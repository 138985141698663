import React from 'react';
import {Button} from '@mui/material';
import {Share} from '@mui/icons-material';
import {SocialShareButtons} from 'app/components/socialShare/socialShareButtons';
import {useShare} from 'app/components/socialShare/useShare';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const ShareButtonInContent = ({text, eventCat, eventSlug, label = 'Teilen', callbackOnShare, callbackOnClose}) => {
  const url = windowIsDefined ? window.location.href : '';
  const {open, handleShare, handleShareClose} = useShare(eventCat, eventSlug, text, callbackOnShare, callbackOnClose);

  return (
    <>
      <Button
        color='secondary'
        onClick={handleShare}
        sx={{fontFamily: 'PT Sans', fontWeight: 'bold'}}
        startIcon={<Share color='inherit' />}
        variant='contained'
        size='small'>
        {label}
      </Button>
      <SocialShareButtons
        url={url}
        text={text}
        open={open}
        onClose={handleShareClose}
        eventCat={eventCat}
        eventSlug={eventSlug}
      />
    </>
  );
};

export {ShareButtonInContent};
