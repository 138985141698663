import {api, getCompetition} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getTopeleven = (competitionSlug, matchDay, seasonSlug = 'current') => {
  const request = `/competitions/${competitionSlug}/seasons/${seasonSlug}/rounds/${matchDay}/topeleven`;
  return api.get(request);
};

function updateLeague(data) {
  return {
    type: 'LEAGUE_TOPELEVEN_META_LEAGUE_UPDATE',
    data,
  };
}

function updateTopEleven(data) {
  return {
    type: 'LEAGUE_TOPELEVEN_UPDATE',
    data,
  };
}

function fetchingTopEleven() {
  return {
    type: `LEAGUE_TOPELEVEN_FETCHING`,
  };
}

function setLeagueHeader(dispatch, league, seasonSlug) {
  if (!league) {
    return;
  }
  const competitionSeasonName =
    seasonSlug && league.season?.name ? `${league.name} ${league.season.name}` : league.name;
  const parent = {
    name: competitionSeasonName,
    entity: 'league',
    slugs: {
      leagueSlug: league.slug,
      seasonSlug: seasonSlug,
    },
  };

  dispatch(
    changeHeaderText({
      title: 'Elf der Woche',
      parent,
    })
  );
}

function fetchLeagueTopElevenSSR(leagueSlug, matchDay, seasonSlug) {
  return async function (dispatch, useState) {
    const response1 = getCompetition(leagueSlug, seasonSlug).then(response => {
      const league = response.data;
      checkApiRedirect(league.slug, leagueSlug, league.season, seasonSlug, useState, league.active);
      dispatch(updateHistory('undefined', {store: 'LeagueTopElevenPage', data: {metaLeague: league}}));
      setLeagueHeader(dispatch, league, seasonSlug);
    });
    const response2 = getTopeleven(leagueSlug, matchDay, seasonSlug).then(response => {
      dispatch(
        updateHistory('undefined', {
          store: 'LeagueTopElevenPage',
          data: {topelevenContent: response.data, isFetching: false},
        })
      );
    });

    try {
      return await Promise.all([response1, response2]);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchLeagueTopEleven(leagueSlug, matchDay, seasonSlug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.metaLeague && Object.keys(state.topelevenContent).length) {
      setLeagueHeader(dispatchRedux, state.metaLeague, seasonSlug);
      return;
    }
    if (state.error) {
      return;
    }

    const response1 = getCompetition(leagueSlug, seasonSlug).then(response => {
      const league = response.data;
      checkApiRedirect(league.slug, leagueSlug, league.season, seasonSlug, null, league.active);
      dispatch(updateLeague(league));
      setLeagueHeader(dispatchRedux, league, seasonSlug);
    });
    dispatch(fetchingTopEleven());
    const response2 = getTopeleven(leagueSlug, matchDay, seasonSlug).then(response => {
      dispatch(updateTopEleven(response.data));
    });

    return Promise.all([response1, response2]).catch(error => {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpError(errorData));
      }
    });
  };
}

export {fetchLeagueTopElevenSSR, fetchLeagueTopEleven};
