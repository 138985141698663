const DistrictMarketplaceDetailPageReducer = (state, action) => {
  switch (action.type) {
    case 'MARKETPLACE_DETAIL_FETCHING':
      return {...state, isFetching: true};
    case 'MARKETPLACE_DETAIL_UPDATE':
      return {...state, detail: action.data, isFetching: false};
    default:
      return state;
  }
};

export {DistrictMarketplaceDetailPageReducer};
