import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {COLORS, Image, TextBadge, IconBadge, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {CustomName, PlayerContainer, PlayerData, Position} from 'app/components/LineUp/LineUpPlayerStyles';
import {ProfilePhoto, ProfileTypography} from 'app/components/profile/ProfileStyles';
import {getPlayerShortName} from 'app/helpers/helpers';
import {Tracktics, ArrowUp, ArrowDown, Card, TwoCards, Football, Assist, SubstitutionInOut} from 'app/components/icons';

const TrackticsIconContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
`;

const LineUpPlayer = ({player, type}) => {
  const stats = player.statistics;
  const JerseyNumber = player.jerseyNumber ? <TextBadge value={player.jerseyNumber.toString()} position={1} /> : '';
  const isDeactivated = player.player.isDeactivated;

  const values = [];
  if (stats.substituteIn && stats.substituteOut) {
    values.push('substitutionInOut');
  } else if (stats.substituteIn) {
    values.push('substitutionIn');
  } else if (stats.substituteOut) {
    values.push('substitutionOut');
  }
  if (stats.redCard) {
    values.push('red');
  } else if (stats.yellowRedCard) {
    values.push('yellowRed');
  } else if (stats.yellowCard) {
    values.push('yellow');
  }
  if (stats.goals || stats.penaltyHits) {
    values.push('goal');
  }
  if (stats.assists) {
    values.push('assist');
  }

  const checkFlags = () => {
    return values.map((value, index) => <IconBadge key={value} value={getIcon(value)} position={3 + index} />);
  };

  const getIcon = value =>
    ({
      substitutionInOut: <SubstitutionInOut style={{fontSize: 12}} />,
      substitutionIn: <ArrowUp style={{color: COLORS.green, fontSize: 12}} />,
      substitutionOut: <ArrowDown style={{color: COLORS.red, fontSize: 12}} />,
      yellowRed: <TwoCards style={{fontSize: 12}} />,
      red: <Card style={{color: COLORS.red, fontSize: 12}} />,
      yellow: <Card style={{color: COLORS.yellowCard, fontSize: 12}} />,
      goal: <Football style={{fontSize: 12}} />,
      assist: <Assist style={{fontSize: 12}} />,
    }[value]);

  let showPosition, flags, captain, showTrackticsIcon;
  if (type !== 'coach') {
    showPosition = (
      <ProfileTypography isDeactivated={isDeactivated} variant='caption1'>
        {player.position}
      </ProfileTypography>
    );
    flags = checkFlags();
    captain = player.captain ? <TextBadge value='C' position={2} /> : '';
  }
  if (player.statistics.details) {
    showTrackticsIcon = (
      <TrackticsIconContainer>
        <Tracktics style={{fontSize: 16}} />
      </TrackticsIconContainer>
    );
  }

  const playerContent = (
    <PlayerContainer>
      <ProfilePhoto>
        {flags}
        {JerseyNumber}
        {captain}
        <Image entity='player' src={player.player.image} circle={true} ratio='1:1' width={64} height={64} />
      </ProfilePhoto>
      <PlayerData>
        <CustomName isDeactivated={isDeactivated} variant='body1'>
          {getPlayerShortName(player.player)}
        </CustomName>
        <Position>
          {showPosition}
          {showTrackticsIcon}
        </Position>
      </PlayerData>
    </PlayerContainer>
  );

  return isDeactivated ? (
    playerContent
  ) : (
    <LinkOnComponent enablehover={true.toString()} to={`/player/${player.player.slug}`}>
      {playerContent}
    </LinkOnComponent>
  );
};

export default LineUpPlayer;
