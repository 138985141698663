import {Content, ContentAdDesktop} from 'app/styles/content';
import {COLORS, FONTS, SIZES, Typography} from '@fupa/fupa-uikit';
import {InnerHtmlText} from 'app/components/text/InnerHtmlText';
import styled from 'styled-components';
import {AdSlot} from 'app/components/ads/AdSlot';

const SCContentAdDesktop = styled(ContentAdDesktop)`
  &&& {
    position: relative;
    padding-top: 0;
    top: ${props => `-${props.top}`};
    ${props => props.theme.desktopLayout`
      top: 0;
    `}
`;

const ImageContainer = styled.div`
  clear: both;
  margin: 1rem 0;
  // set min-height to container to reduce cls
  min-height: 56.25vw;
  ${props => props.theme.desktopLayout`
    min-height: ${props => (props.theme.desktopContentWidth * 9) / 16}px;
  `}
`;

const HeroElement = styled.div`
  display: flex;
  justify-content: center;
  // Ratio 3:2
  width: 100%;
  height: 66.66vw;
  overflow: hidden;
  max-height: ${props => props.theme.desktopContentWidth / 1.5}px;
  max-width: ${props => props.theme.desktopContentWidth}px;
  margin: 0 auto;
  background-color: ${COLORS.white};
`;

const Header = styled.div`
  padding-bottom: 2rem;
`;

const HeadlineNews = styled(Typography)`
  &&& {
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  ${props => props.theme.desktopLayout`
    &&& {
      font-size: 2.5rem;
    }
  `}
`;

const Subtitle = styled(Typography)`
  margin: 0;
  margin-top: 0.5rem;
  font-size: ${FONTS['18']};
  ${props => props.theme.desktopLayout`
    &&& {
      font-size: ${FONTS['20']};
      margin-top: 1rem;
    }
  `}
`;

const ImageDescription = styled.span`
  display: inline-flex;
  font-size: ${SIZES['12']};
  color: ${COLORS.darkerGrey};
  padding: 0 1rem;
`;

const TextContent = styled(Content)`
  &&& {
    display: block;
    padding: 0 1rem 1rem;
    height: 100%;
    overflow-x: hidden;
    border: none;
    ${props => props.theme.desktopLayout`
      padding-right: 3rem;
      padding-left: 3rem;
  `}
`;

const TeaserText = styled(InnerHtmlText)`
  ${props => props.theme.desktopLayout`
    padding-bottom: 1.5rem;
  `}
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  ${props => props.theme.desktopLayout`
      padding-right: 3rem;
      padding-left: 3rem;
  `}
`;

const Meta = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.span`
  display: inline-flex;
  color: ${COLORS.darkestGrey};
  font-size: ${SIZES['12']};
  line-height: 1rem;
`;

const DesktopShareFabButton = styled.div`
  display: none;
  ${props => props.theme.desktopLayout`
    display: flex;
    position: fixed;
    margin-left: ${props.theme.desktopContentWidth - props.theme.fabWidth}px;
    z-index: ${props.theme.zIndexFab};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    max-width: 14rem;
    bottom: 0.5rem;
  `}

  ${props => props.theme.desktopLayoutWithFab`
    margin-left: ${props.theme.desktopContentWidth}px;
  `}
`;
const DesktopEditFabButton = styled(DesktopShareFabButton)`
  &&& {
    bottom: ${SIZES[64]};
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  padding-top: 25%;
`;

const SCAdSlotFloating = styled(AdSlot)`
  clear: right;
  &&& {
    margin: 1.25rem 0;
  }
  ${props => props.theme.desktopLayout`
    &&& {
      width: auto;
      margin-left: 1rem;
      float: right;
    }
  `}
`;

const VgWortImg = styled.img`
  position: absolute;
`;

export {
  SCContentAdDesktop,
  ImageContainer,
  HeroElement,
  Header,
  HeadlineNews,
  Subtitle,
  ImageDescription,
  TextContent,
  TeaserText,
  Details,
  Meta,
  Info,
  DesktopShareFabButton,
  DesktopEditFabButton,
  LoadingWrapper,
  SCAdSlotFloating,
  VgWortImg,
};
