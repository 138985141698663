import {useEffect, useRef, useState} from 'react';
import {
  executeTrigger,
  useCheckTriggerViewportPosition,
  useCommonNativeRatingDialogConditions,
} from 'app/hooks/nativeRatingDialogTrigger/triggerNativeRatingDialog';
import {useNative} from 'app/contexts/NativeContext';

const useTopelevenNRDTrigger = (contentLoaded, ratingTriggerRef) => {
  const nativeInfo = useNative();
  const [isInViewport, setIsInViewport] = useState(false);
  const commonConditionUnfilled = useCommonNativeRatingDialogConditions(contentLoaded, nativeInfo);

  // use ref instead of component state to store variables as mutable values that exist for the lifetime of the page component
  const stateRef = useRef({
    commonConditionUnfilled,
    requestedTrigger: false,
  });

  const setRequestedTrigger = requestedTrigger => {
    stateRef.current = {...stateRef.current, requestedTrigger};
  };

  let timer;
  useEffect(() => {
    const {requestedTrigger, commonConditionUnfilled} = stateRef.current;
    // Condition 3: Call rating dialog only if user has scrolled TopelevenInformation over 20% into viewport
    // Prevent calling rating dialog twice
    if (commonConditionUnfilled || requestedTrigger || !isInViewport) {
      return;
    }

    const handleTrigger = () => {
      executeTrigger(0.6, setRequestedTrigger, 'topeleven', nativeInfo);
    };
    timer = setTimeout(handleTrigger, 3000);
  }, [isInViewport]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useCheckTriggerViewportPosition(ratingTriggerRef, 0.8, contentLoaded, setIsInViewport);

  useEffect(() => {
    stateRef.current = {...stateRef.current, commonConditionUnfilled};
  }, [commonConditionUnfilled]);
};
export {useTopelevenNRDTrigger};
