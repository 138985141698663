import React from 'react';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {Person} from '@mui/icons-material';
import styled from 'styled-components';

const Component = styled.div`
  align-self: flex-end;
`;

const Name = styled(Typography)`
  &&& {
    color: ${COLORS.black};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Description = styled.div`
  display: flex;
  line-height: 1rem;
  text-align: right;
  padding-right: 0.5rem;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid ${COLORS.white};
  background-color: ${COLORS.lightestGrey};
  align-items: center;
`;

const Author = ({image, job, name}) => {
  const avatar = image ? (
    <Image entity='user' src={image} width={32} ratio='1:1' height={32} circle={true} />
  ) : (
    <IconWrapper>
      <Person style={{color: COLORS.lightGrey}} />
    </IconWrapper>
  );

  return (
    <Component>
      <Container>
        <Description>
          <Name variant='caption1'>{name ?? 'Unbekannt'}</Name>
          <Typography variant='caption1'>{job}</Typography>
        </Description>
        {avatar}
      </Container>
    </Component>
  );
};

export default Author;
