import React from 'react';
import LineUpPlayer from 'app/components/LineUp/LineUpPlayer';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const LineUp = ({lineup, type}) => {
  const displayPlayers = lineup.map(player =>
    player.player ? <LineUpPlayer key={player.player.slug} player={player} type={type} /> : null
  );

  return <Container>{displayPlayers}</Container>;
};

export default LineUp;
