import React from 'react';
import {TopelevenPlayer} from 'app/components/topeleven/TopelevenPlayer';
import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';
import {SponsoringCenter} from 'app/components/topeleven/TopElevenPageSponsoring';

const LineUpContainer = styled.div`
  width: inherit;
  max-width: 30rem;
  padding: ${SIZES['12']} 0;
`;

const Goalkeeper = styled(FlexContainer)`
  width: 100%;
  justify-content: center;
`;

const Players = styled(FlexContainer)`
  width: 100%;

  & > div {
    flex: 1 33%;
  }
`;

const TopElevenTeam = ({topeleven}) => {
  let goalkeeper, defense, defensiveMid, offensiveMid, attack;
  if (Object.keys(topeleven).length > 0) {
    goalkeeper = topeleven.starting
      .filter(player => player.position === 'Torwart')
      .map((player, index) => (
        <TopelevenPlayer key={player.player?.slug ?? `goalkeeper-${index}`} player={player} time={1000} />
      ));
    defense = topeleven.starting
      .filter(player => player.position === 'Abwehr')
      .map((player, index) => (
        <TopelevenPlayer key={player.player?.slug ?? `defense-${index}`} player={player} time={2000} />
      ));
    const midfield = topeleven.starting.filter(player => player.position === 'Mittelfeld');
    defensiveMid = (
      <Players>
        <TopelevenPlayer player={midfield[2]} time={3000} />
        <SponsoringCenter />
        <TopelevenPlayer player={midfield[3]} time={3000} />
      </Players>
    );
    offensiveMid = midfield
      .slice(0, 2)
      .map((player, index) => (
        <TopelevenPlayer key={player.player?.slug ?? `midfield-${index}`} player={player} time={3000} />
      ));
    attack = topeleven.starting
      .filter(player => player.position === 'Angriff')
      .map((player, index) => (
        <TopelevenPlayer key={player.player?.slug ?? `attack-${index}`} player={player} time={4000} />
      ));
  }

  return (
    <LineUpContainer>
      <Goalkeeper>{goalkeeper}</Goalkeeper>
      <Players>{defense}</Players>
      <Players>{defensiveMid}</Players>
      <Players>{offensiveMid}</Players>
      <Players>{attack}</Players>
    </LineUpContainer>
  );
};

export default TopElevenTeam;
