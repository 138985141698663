import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Image, ImageBadge, Typography, IconBadge} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';
import {CustomName, PlayerContainer, PlayerData} from 'app/components/LineUp/LineUpPlayerStyles';
import {FlexContainer} from 'app/styles/content';
import {useSelector} from 'react-redux';
import {ProfilePhoto} from 'app/components/profile/ProfileStyles';
import {number, object} from 'prop-types';
import {selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import {Person} from '@mui/icons-material';
import {Emblem, FuPaPlayer} from 'app/components/icons';

const Wrapper = styled.div`
  position: relative;
  height: 7.125rem;
  min-width: ${SIZES['64']};
`;

const TopElevenPlayerContainer = styled(PlayerContainer)`
  display: flex;
  opacity: ${props => (props.active ? 1 : 0)};
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
`;

const CustomPosition = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

const TopElevenName = styled(CustomName)`
  &&& {
    color: ${COLORS.white};
  }
`;

const Placeholder = styled(FlexContainer)`
  position: absolute;
  width: ${SIZES['64']};
  height: ${SIZES['64']};
  border-radius: ${SIZES['32']};
  background-color: ${COLORS.white};
  top: 0.5rem;
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: ${props => (props.active ? 0 : 1)};
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
`;

const ImagePlaceholder = styled.div`
  width: ${SIZES['64']};
  height: ${SIZES['64']};
  border-radius: ${SIZES['32']};
  background-color: ${COLORS.white};
`;

const TopelevenPlayer = ({player, time}) => {
  const ssrRendered = useSelector(selectSsrRendered);
  const [isActive, setIsActive] = useState(ssrRendered);

  useEffect(() => {
    let timeout;
    if (!isActive) {
      timeout = setTimeout(() => setIsActive(true), time);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const checkImage = player?.player?.image ? (
    <Image entity='player' src={player.player.image} circle={true} ratio='1:1' width={64} height={64} eager={true} />
  ) : (
    <ImagePlaceholder>
      <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
    </ImagePlaceholder>
  );

  const checkTeamBadge = player?.team?.image ? (
    <ImageBadge value={player.team.image} position={1} fit={true} />
  ) : (
    <IconBadge value={<Emblem style={{color: COLORS.grey, fontSize: 12}} />} position={1} />
  );

  return (
    <Wrapper>
      <LinkOnComponent to={player?.player ? `/player/${player.player.slug}` : ''}>
        <TopElevenPlayerContainer active={isActive}>
          <ProfilePhoto>
            {checkTeamBadge}
            {checkImage}
          </ProfilePhoto>
          <PlayerData>
            <TopElevenName variant='body1'>{player?.player ? player.player.lastName : 'unbekannt'}</TopElevenName>
            <CustomPosition variant='caption1'>{player?.position}</CustomPosition>
          </PlayerData>
        </TopElevenPlayerContainer>
        <Placeholder active={isActive}>
          <FuPaPlayer style={{color: COLORS.fupaSecondary, fontSize: 32}} />
        </Placeholder>
      </LinkOnComponent>
    </Wrapper>
  );
};

TopelevenPlayer.propTypes = {
  player: object.isRequired,
  time: number.isRequired,
};

export {TopelevenPlayer};
