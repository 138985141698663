import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {Image, LOADING_MESSAGE, LoadingIndicator} from '@fupa/fupa-uikit';
import {NewsAppBar} from 'app/routes/news/NewsAppBar';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useClickTracking} from 'app/hooks/useClickTracking';
import Author from 'app/components/author/Author';
import {numberWithThousandSeparator} from 'app/helpers/helpers';
import {formatDateWithWeekday, formatLongDate} from 'app/helpers/dateHelpers';
import {fetchNews} from 'app/routes/news/NewsPageActions';
import {ShareFabButton} from 'app/components/socialShare/ShareFabButton';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {MatchRow} from 'app/components/match/matchRow/MatchRow';
import {NewsLinkings} from 'app/components/news/NewsLinkings';
import {useHistory, useLocation, useParams} from 'react-router';
import {InnerHtmlText} from 'app/components/text/InnerHtmlText';
import {useSiteSpecificAdTargeting} from 'app/components/ads/useAdTargeting';
import {
  DesktopEditFabButton,
  DesktopShareFabButton,
  Details,
  Header,
  HeadlineNews,
  HeroElement,
  ImageContainer,
  ImageDescription,
  Info,
  LoadingWrapper,
  Meta,
  SCAdSlotFloating,
  SCContentAdDesktop,
  Subtitle,
  TeaserText,
  TextContent,
  VgWortImg,
} from 'app/components/news/NewsStylings';
import {EditFab} from 'app/components/floatingAction/EditFab';
import {selectAuthState, selectSsrRendered} from 'app/hooks/reduxCreateSelectorHooks';
import PartnerTeaser from 'app/components/partner/PartnerTeaser';
import {getPermission} from 'app/services/api';
import {useNewsNRDTrigger} from 'app/hooks/nativeRatingDialogTrigger/useNewsNRD';
import {useEffectAfterMount, useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {NewsPageReducer} from 'app/routes/news/NewsPageReducer';
import {Backdrop} from '@mui/material';
import {setCacheMaxAge} from 'app/helpers/setCacheMaxAge';
import {CompetitionCaption} from 'app/styles/heading';
import NotFoundPage from 'app/routes/error/NotFoundPage';
import encodeHTML from 'app/helpers/encodeHTMLInStrings';
import {styled} from '@mui/material/styles';
import MobileBannerAdSlot from 'app/components/ads/MobileBannerAdSlot';
import {RedirectException} from 'app/helpers/apiHelpers';
import useTaboola from 'app/components/ads/useTaboola';
import TaboolaFeed from 'app/components/ads/TaboolaFeed';
import useEnableTaboolaFeed from 'app/hooks/useEnableTaboolaFeed';

const StyledImage = styled(Image)`
  &&& {
    @media only screen and (max-width: 970px) {
      width: ${props => props.dimX ?? '100vw'};
    }
  }
`;

const shortCodeToken = '###_SHORTCODE_###';
const shortCodeMapping = {
  BILD: attachment => {
    if (attachment) {
      const source = attachment.source ? ` – Foto: ${attachment.source}` : '';
      const description = attachment.description ? attachment.description : '';
      const subline =
        description || source ? (
          <ImageDescription>
            {description}
            {source}
          </ImageDescription>
        ) : null;
      return (
        <ImageContainer key={attachment.baseName}>
          {/* actualWidthFactor 1,0526315789 is calculated with (contentWidth-padding)/contentWidth*/}
          <Image entity='news' actualWidthFactor={1.0526315789} ratio='original' src={attachment} />
          {subline}
        </ImageContainer>
      );
    } else {
      return null;
    }
  },
  ADVERT: (_, adUnit, isSponsoringNews) =>
    isSponsoringNews ? null : <SCAdSlotFloating key={adUnit} adUnit={adUnit} limitedDim={true} />,
  MATCH: attachment =>
    attachment ? (
      <MatchRow
        key={attachment.id}
        standalone={true}
        match={attachment}
        caption={
          <CompetitionCaption variant='caption1'>{formatDateWithWeekday(attachment.kickoff, true)}</CompetitionCaption>
        }
        colored={false}
      />
    ) : null,
};

const getSchemaMarkup = newsDetail => {
  const players = newsDetail.links.players.map(comp => {
    return comp.firstName + ' ' + comp.lastName;
  });

  const competitions = newsDetail.links.competitions.map(comp => {
    return comp.name;
  });

  const teams = newsDetail.links.teams.map(comp => {
    return comp.name.full;
  });

  const keywords = [...competitions, ...teams, ...players];

  const author = newsDetail.author.name;

  const mainText = newsDetail.teaserText + ' ' + newsDetail.text;
  const articleBody = mainText.replace(/<[^>]*>/g, '');

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://www.fupa.net/news/' + newsDetail.slug,
    },
    headline: newsDetail.title,
    image: [
      {
        '@type': 'ImageObject',
        url: newsDetail.image.path + '1200x1200.jpeg',
        width: 1200,
        height: 1200,
      },
      {
        '@type': 'ImageObject',
        url: newsDetail.image.path + '1440x1080.jpeg',
        width: 1440,
        height: 1080,
      },
      {
        '@type': 'ImageObject',
        url: newsDetail.image.path + '2000x1125.jpeg',
        width: 2000,
        height: 1125,
      },
      {
        '@type': 'ImageObject',
        url: newsDetail.image.path + '1920xauto.jpeg',
        width: 1920,
        height: 'auto',
      },
    ],
    datePublished: newsDetail.publishDate,
    dateModified: newsDetail.modifiedDate,
    description: newsDetail.subtitle,
    keywords,
    author: [
      {
        '@type': 'Person',
        name: author,
      },
    ],
    publisher: {
      '@type': 'Organization',
      name: 'FuPa',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.fupa.net/fupa_logo_180x60.png',
      },
    },
    isAccessibleForFree: true,
    articleBody,
  };

  return encodeHTML(JSON.stringify(schema));
};

const getMetaInformation = newsDetail => {
  const {pathname, search} = useLocation();
  let title = '';
  let meta = [
    {property: 'og:type', content: 'article'},
    {property: 'og:site_name', content: 'FuPa'},
    {property: 'fb:app_id', content: `939336572887264`},
  ];
  if (Object.keys(newsDetail).length) {
    title = newsDetail.title + TITLE_POSTFIX;

    const ogTitle = {
      property: 'og:title',
      content: newsDetail.title,
    };

    const setIndex = newsDetail.archivedDate
      ? {name: 'robots', content: 'noindex, follow, max-image-preview:large'}
      : {name: 'robots', content: 'index, follow, max-image-preview:large'};

    const newsWithDesc = newsDetail.subtitle
      ? {
          name: 'description',
          content: newsDetail.subtitle,
        }
      : {};

    const ogDesc = newsDetail.subtitle
      ? {
          property: 'og:description',
          content: newsDetail.subtitle,
        }
      : {};

    const isPreview = search
      .replace('?', '')
      .split('&')
      .find(param => param.startsWith('debug='))
      ?.includes('preview');
    const url = isPreview ? pathname + `?debug=preview` : pathname;
    const ogUrl = url
      ? {
          property: 'og:url',
          content: `https://www.fupa.net${url}`,
        }
      : {};

    const imageSize = {height: '540', width: '960'};
    const imageUrl = newsDetail.image?.path
      ? `${newsDetail.image.path}${imageSize.width}x${imageSize.height}.jpeg`
      : '';
    const ogImage = newsDetail.image?.path
      ? [
          {property: 'og:image', content: imageUrl},
          {property: 'og:image:secure_url', content: imageUrl},
          {property: 'og:image:type', content: 'image/jpeg'},
          {property: 'og:image:width', content: `${imageSize.width}px`},
          {property: 'og:image:height', content: `${imageSize.height}px`},
        ]
      : [];

    meta.push(setIndex, ogTitle, newsWithDesc, ogDesc, ogUrl);
    meta = meta.concat(ogImage);
  }

  return {title, meta};
};

const socialMediaEmbeddings = [
  {selector: 'blockquote.twitter-tweet', src: 'https://platform.twitter.com/widgets.js'},
  {selector: 'blockquote.instagram-media', src: 'https://www.instagram.com/embed.js'},
];

const MAX_AGE_TICKER_NEWS_IN_SECONDS = 10;
const MAX_AGE_LATEST_NEWS_IN_SECONDS = 5 * 60;

const NewsPage = () => {
  const {restoredData} = useRestoreData('NewsPage');
  const {newsSlug} = useParams();
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {getState} = useStore();
  const initialData = {isFetching: false, data: {}};
  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(NewsPageReducer, initialState);
  const loadData = forceRefresh =>
    fetchNews(newsSlug, location?.state?.updatedAt, dispatchRedux, forceRefresh, getState)(dispatch, state);
  const {isFetching, data} = state;
  useFetchData(state, loadData, 'NewsPage');

  // Necessary if news page does not unmount
  // Request data on switching news by auto redirect or link to another news page
  useEffectAfterMount(() => {
    loadData(true).catch(error => {
      if (error instanceof RedirectException) {
        // Make redirect triggered by Redirect from API
        history.replace(error.target);
      }
    });
  }, [newsSlug]);

  const isAuthenticated = useSelector(selectAuthState);
  const isHydrating = useSelector(selectSsrRendered);
  const [ssr, setSSR] = useState(typeof window === 'undefined' || isHydrating);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const {title, meta} = getMetaInformation(data);
  const autoRedirect = useMemo(() => {
    if (!data?.auto_redirect_url) {
      return null;
    }
    if (data.auto_redirect_url !== location.pathname) {
      return data.auto_redirect_url;
    }
    return null;
  }, [data?.auto_redirect_url]);
  const ratingTriggerRef = useRef(null);
  const contentLoaded = data.text && !isFetching;
  useNewsNRDTrigger(contentLoaded, ratingTriggerRef);

  if (isHydrating && restoredData?.data?.publishDate) {
    const today = new Date().toLocaleDateString('de-DE');
    const publishDate = new Date(restoredData?.data?.publishDate).toLocaleDateString('de-DE');
    if (today === publishDate) {
      setCacheMaxAge(MAX_AGE_LATEST_NEWS_IN_SECONDS);
    }
  }
  // Set shorter cache-control header for news of category "Ticker" (id: 11) only when ssr rendering
  if (isHydrating && restoredData?.data?.category?.description === 'Ticker') {
    setCacheMaxAge(MAX_AGE_TICKER_NEWS_IN_SECONDS, MAX_AGE_TICKER_NEWS_IN_SECONDS);
  }

  useEffect(() => {
    setSSR(false);
  }, []);

  useEffect(() => {
    if (!ssr && autoRedirect) {
      history.replace(autoRedirect);
    }
  }, [data, ssr]);

  // check authorization for edit button
  useEffect(async () => {
    if (data?.id && !isAuthorised) {
      getPermission('news', data.id)
        .then(response => {
          setIsAuthorised(response.data?.edit);
        })
        .catch(error => {
          if (error.status === 401) {
            setIsAuthorised(false);
          }
        });
    } else if (!isAuthenticated && isAuthorised) {
      setIsAuthorised(false);
    }
  }, [data, isAuthenticated]);

  const targetingValues = useMemo(() => {
    const club = data?.links?.teams?.map(team => team.clubSlug);
    const competition = data?.links?.competitions?.map(competition => competition.slug);
    return {club, competition};
  }, [data?.links]);

  const {club, competition} = targetingValues;
  useSiteSpecificAdTargeting({club, competition}, [data?.links]);

  useEffect(() => {
    function loadSocialMediaScript(src) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    }

    socialMediaEmbeddings.map(channel => {
      const isEmbedded = document.querySelector(channel.selector);
      if (isEmbedded) {
        loadSocialMediaScript(channel.src);
      }
    });
  }, [data]);

  const newsDetail = data;
  const isSponsoringNews = newsDetail.category?.id === 10;

  const [enableTaboolaFeed] = useEnableTaboolaFeed();
  const initTaboola = !isSponsoringNews && enableTaboolaFeed;
  useTaboola(initTaboola, newsSlug);

  const {clicks} = useClickTracking('news', newsDetail);

  let content;
  if (isFetching) {
    content = <LoadingIndicator bgColor message={LOADING_MESSAGE.loadingData} />;
  }

  if (newsDetail.status && newsDetail.message) {
    return <NotFoundPage id={`http-error-${newsDetail.status}`} text={newsDetail.message} top={'7.5rem'} />;
  }

  if (!isFetching && !newsDetail.text) {
    return null;
  }

  if (!isFetching) {
    let imageDesc;
    if (newsDetail?.image) {
      const description = newsDetail.image.description || '';
      const source = newsDetail.image.source ? ` – Foto: ${newsDetail.image.source}` : '';
      imageDesc = (
        <ImageDescription>
          {description}
          {source}
        </ImageDescription>
      );
    }

    const author = newsDetail.author;

    const replaceShortCodes = text => {
      function replacer(match) {
        return shortCodeToken + match + shortCodeToken;
      }
      return text.replace(/\[[A-Z0-9-_]+\]/g, replacer);
    };

    const replacedShortCodesText = replaceShortCodes(newsDetail.text);
    const mainTextParts = replacedShortCodesText.split(shortCodeToken);
    const mainTextComponents = mainTextParts.map((part, index) => {
      if (part.match(/\[[A-Z0-9-_]+\]/)) {
        const removeBrackets = /\[|\]/g;
        const type = part.replace(removeBrackets, '').split('-');
        let adUnit = '';
        if (type[0] === 'ADVERT' && type[1]) {
          // Transform "RECTANGLE_1" to "Rectangle_1"
          const str = type[1].toLowerCase();
          adUnit = str.charAt(0).toUpperCase() + str.slice(1);
        }
        return shortCodeMapping[type[0]](newsDetail.attachments[part], adUnit, isSponsoringNews);
      } else {
        return <InnerHtmlText text={part} key={index} />;
      }
    });

    const adUnit1 = isSponsoringNews ? null : <SCAdSlotFloating adUnit='Content_1' limitedDim={true} />;

    const editUrl = `${process.env.ADMIN_URL}/fupa/admin/index.php?page=news_edit2&aktion=edit&news_id=${newsDetail.id}`;
    const editBtn =
      isAuthorised && newsDetail.id ? (
        <DesktopEditFabButton>
          <EditFab secondary url={editUrl} />
        </DesktopEditFabButton>
      ) : null;

    const vgWortUrl = `https://vg08.met.vgwort.de/na/vgzm.2780411-${newsDetail.id}`;
    const vgWortPixel = autoRedirect ? null : <VgWortImg src={vgWortUrl} height='1' width='1' border='0' />;

    const schema = getSchemaMarkup(newsDetail);

    const canonical = location?.search ? <link rel='canonical' href={`https://www.fupa.net/news/${newsSlug}`} /> : null;
    const bannerAdSlot =
      newsDetail?.teaserText && !isSponsoringNews ? <MobileBannerAdSlot margin={'0 0 0.5rem 0'} /> : null;

    content = (
      <>
        <Helmet title={title} meta={meta}>
          {canonical}
          <script type='application/ld+json'>{schema}</script>
        </Helmet>
        {editBtn}
        <DesktopShareFabButton>
          <ShareFabButton isPrimaryAction text={newsDetail.title} eventCat='news' eventSlug={newsSlug} />
        </DesktopShareFabButton>
        {vgWortPixel}
        <NewsAppBar title={newsDetail.title} editUrl={editUrl} isAuthorised={isAuthorised} newsSlug={newsSlug} />
        <Backdrop
          sx={{
            zIndex: 1,
            backgroundColor: 'rgba(256, 256, 256, 0.6)',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: 'flex-start',
          }}
          open={!!autoRedirect}>
          <LoadingWrapper>
            <LoadingIndicator bgColor message={'Du wirst automatisch weitergeleitet...'} />
          </LoadingWrapper>
        </Backdrop>
        <HeroElement>
          <StyledImage
            eager={true}
            entity='news'
            ratio='3:2'
            src={newsDetail.image}
            sizesHint='(max-width: 970px) 100vw, 968px'
          />
        </HeroElement>
        {imageDesc}
        <TextContent>
          <PartnerTeaser noPadding alwaysReserve={true} />
          <Header>
            <HeadlineNews variant='headline2' component='h1'>
              {newsDetail.title}
            </HeadlineNews>
            <Subtitle variant='subheading' component='h2'>
              {newsDetail.subtitle}
            </Subtitle>
            <NewsLinkings countInitDisplayedLinks={5} links={newsDetail.links} noPadding={true} />
          </Header>
          {bannerAdSlot}
          <TeaserText text={newsDetail.teaserText} />
          {adUnit1}
          {mainTextComponents}
        </TextContent>
        <Details>
          <Meta>
            <Info>Aufrufe: {numberWithThousandSeparator(clicks)}</Info>
            <Info>{formatLongDate(newsDetail.publishDate)} Uhr</Info>
          </Meta>
          <Author image={author.image} job='Autor' name={author.name} />
        </Details>
        <div ref={ratingTriggerRef} />
        {initTaboola && <TaboolaFeed />}
      </>
    );
  }

  return (
    <SCContentAdDesktop noContentPadding noAds={isSponsoringNews} noTabs noPartnerTeaser top='4.5rem'>
      {content}
    </SCContentAdDesktop>
  );
};

export default NewsPage;
