import {api} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError} from 'app/helpers/apiHelpers';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getMarketplaceDetail = id => {
  return api.get(`/marketplace/${id}`);
};

function updateMarketplaceDetail(data) {
  return {
    type: 'MARKETPLACE_DETAIL_UPDATE',
    data,
  };
}

function fetchingMarketplaceDetail() {
  return {
    type: 'MARKETPLACE_DETAIL_FETCHING',
  };
}

function setMarketplaceDetailHeader(dispatch, element) {
  const parent = {
    name: '',
    entity: '',
  };

  dispatch(
    changeHeaderText({
      title: element.category.name,
      parent,
    })
  );
}

function fetchDistrictMarketplaceDetailSSR(id) {
  return async function (dispatch) {
    try {
      let response = await getMarketplaceDetail(id);
      const element = response.data;
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictMarketplaceDetailPage',
          data: {detail: element, isFetching: false, error: null},
        })
      );
      setMarketplaceDetailHeader(dispatch, element);
    } catch (error) {
      const errorData = catchDataError(error);
      dispatch(updateHttpError(errorData));
    }
  };
}

function fetchDistrictMarketplaceDetail(id, dispatchRedux) {
  return function (dispatch, state) {
    if (Object.keys(state.detail).length) {
      setMarketplaceDetailHeader(dispatchRedux, state.detail);
      return;
    }
    dispatch(fetchingMarketplaceDetail());
    return getMarketplaceDetail(id)
      .then(response => {
        const element = response.data;
        dispatch(updateMarketplaceDetail(element));
        setMarketplaceDetailHeader(dispatchRedux, element);
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpError(errorData));
      });
  };
}

export {fetchDistrictMarketplaceDetailSSR, fetchDistrictMarketplaceDetail};
