import axios from 'axios';
import {useState} from 'react';

const getDeepLink = matchInfo => {
  // create deep-link for sport.video
  const competitionSeasonId = matchInfo.competition.competitionSeasonId;
  const homeTeamId = matchInfo?.homeTeam?.id;
  const awayTeamId = matchInfo?.awayTeam?.id;
  const data = {
    league__ext_id: competitionSeasonId,
    home_team__ext_id: homeTeamId,
    away_team__ext_id: awayTeamId,
  };

  return axios.post('https://sport.video/api/partner-link/', data);
};

const useSportVideoDeepLink = () => {
  const [deepLink, setDeepLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const generateDeepLink = async matchInfo => {
    setIsLoading(true);
    try {
      const response = await getDeepLink(matchInfo);
      if (response.status === 200) {
        setIsLoading(false);
        setDeepLink(response.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {generateDeepLink, deepLink, isLoading};
};

const openDeepLink = deepLink => {
  window.open(deepLink);
};

export {useSportVideoDeepLink, openDeepLink};
