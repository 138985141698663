import React from 'react';
import {Fab, Tooltip} from '@mui/material';
import {Share} from '@mui/icons-material';
import {COLORS} from '@fupa/fupa-uikit';
import {SocialShareButtons} from 'app/components/socialShare/socialShareButtons';
import {useShare} from 'app/components/socialShare/useShare';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const ShareFabButton = ({text, isPrimaryAction, eventCat, eventSlug}) => {
  const {open, handleShare, handleShareClose} = useShare(eventCat, eventSlug, text);

  const url = windowIsDefined ? window.location.href : '';

  const fabColor = isPrimaryAction ? 'secondary' : 'default';
  const styleProps = isPrimaryAction
    ? {margin: '0.5rem 1rem'}
    : {color: COLORS.darkerGrey, backgroundColor: COLORS.white, margin: '0.5rem 1rem'};

  return (
    <>
      <Tooltip title='Teilen' placement='right'>
        <Fab color={fabColor} sx={styleProps} onClick={handleShare} size='small' aria-label='share'>
          <Share />
        </Fab>
      </Tooltip>
      <SocialShareButtons
        url={url}
        text={text}
        open={open}
        onClose={handleShareClose}
        eventCat={eventCat}
        eventSlug={eventSlug}
      />
    </>
  );
};

export {ShareFabButton};
