import {Button, COLORS, SIZES} from '@fupa/fupa-uikit';
import {convertToRoman} from 'app/helpers/convertToRoman';
import React, {useState} from 'react';
import {Subheading} from 'app/styles/heading';
import styled, {css} from 'styled-components';
import {generateBasicCompetitionLink} from 'app/helpers/actionsHelpers';
import {Avatar, Chip as MuiChip, Stack} from '@mui/material';
import ChipImage from 'app/components/chip/ChipImage';
import {FPLink} from 'app/components/links/link';

const Wrapper = styled.div`
  padding: ${props => (props.noPadding ? 0 : '0 1rem 1rem')};

  ${props =>
    props.theme.desktopLayout`
     padding-top: ${props => (props.noPadding ? '2rem' : 0)};
    ${props =>
      !props.noPadding &&
      css`
        padding-right: 3rem;
        padding-left: 3rem;
      `}
  `}
`;

const NewsLinkings = ({links, countInitDisplayedLinks = 10, noPadding}) => {
  const [showMore, setShowMore] = useState(false);

  const linkedCompetitions = links.competitions.map(competition => {
    const label = competition.active ? competition.middleName : `${competition.middleName} ${competition.season.name}`;
    const link = generateBasicCompetitionLink(competition);
    return (
      <MuiChip
        key={competition.slug}
        avatar={<ChipImage entity='competition' src={competition.image} fit={true} />}
        label={label}
        component={FPLink}
        rel={competition.active ? 'follow' : 'nofollow'}
        to={link}
        variant='outlined'
        clickable
      />
    );
  });

  const linkedTeams = links.teams.map(team => {
    let label = team.name.middle;
    label += team.level > 1 ? ` ${convertToRoman(team.level)}` : '';
    return (
      <MuiChip
        key={team.slug}
        avatar={<ChipImage entity='emblem' src={team.image} fit={true} />}
        label={label}
        component={FPLink}
        to={`/team/${team.slug}`}
        rel='nofollow'
        variant='outlined'
        clickable
      />
    );
  });

  const linkedPlayers = links.players.map(player => {
    const link = `/player/${player.slug}`;
    let label = `${player.firstName} ${player.lastName}`;
    return (
      <MuiChip
        key={player.slug}
        avatar={<ChipImage entity='player' src={player.image} circle={true} ratio='1:1' />}
        label={label}
        component={FPLink}
        to={link}
        rel='follow'
        variant='outlined'
        clickable
      />
    );
  });

  const linkedNews = links.news.map(news => {
    const link = `/news/${news.slug}`;
    const label = news.title.length < 28 ? news.title : `${news.title.substring(0, 25)}...`;
    return (
      <MuiChip
        sx={{
          '& .MuiChip-avatar': {
            fontSize: SIZES['16'],
          },
        }}
        key={news.slug}
        avatar={<Avatar sx={{bgcolor: COLORS.white}}>📰</Avatar>}
        label={label}
        component={FPLink}
        to={link}
        rel='follow'
        variant='outlined'
        clickable
      />
    );
  });

  const allLinkings = [...linkedCompetitions, ...linkedTeams, ...linkedPlayers, ...linkedNews];

  let topLinks = null;
  if (allLinkings.length > countInitDisplayedLinks + 1) {
    topLinks = allLinkings.slice(0, countInitDisplayedLinks);
  }

  const moreButton =
    topLinks?.length && !showMore ? (
      <Button
        text
        label={`+ ${allLinkings.length - countInitDisplayedLinks} weitere`}
        onClick={() => setShowMore(true)}
      />
    ) : null;

  const linkings = showMore || !topLinks ? allLinkings : topLinks;

  if (!linkings.length) {
    return null;
  }

  return (
    <Wrapper noPadding={noPadding}>
      <Subheading variant='subheading2'>Verlinkte Inhalte</Subheading>
      <Stack gap={1} direction='row' flexWrap={'wrap'}>
        {linkings}
        {moreButton}
      </Stack>
    </Wrapper>
  );
};

export {NewsLinkings};
