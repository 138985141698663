import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {SIZES} from '@fupa/fupa-uikit';

const HomeHighlight = styled(FlexContainer)`
  align-items: center;
  width: 100%;
  text-align: right;
  height: ${SIZES['24']};
  max-width: 30rem;
`;

const AwayHighlight = styled(HomeHighlight)`
  &&& {
    text-align: left;
    flex-direction: row-reverse;
  }
`;

const Name = styled.div`
  line-height: ${SIZES['16']};
  flex: 1 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Event = styled(Name)`
  &&& {
    flex: 1 10%;
  }
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SIZES['16']};
  line-height: ${SIZES['16']};
  flex: 1 10%;
`;

export {HomeHighlight, AwayHighlight, Name, Event, ElementContainer};
