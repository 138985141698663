import React from 'react';
import {FlexContainer} from 'app/styles/content';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const TEPlaceholder = styled(FlexContainer)`
  align-items: flex-start;
  padding-top: ${SIZES['32']};
  justify-content: center;
  text-align: center;
`;

const CustomTypography = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

const TopElevenPlaceholder = () => {
  return (
    <TEPlaceholder>
      <CustomTypography variant='body1'>
        Aufgrund zu weniger Stimmen kam leider keine Auswertung zustande.
      </CustomTypography>
    </TEPlaceholder>
  );
};

export default TopElevenPlaceholder;
