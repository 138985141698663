import {SponsorImageInterface} from 'app/components/partner/SponsorImageInterface';
import {District} from 'app/types/District';

export const sponsoringPartnerByDistrict = (districtSlug: string): SponsorImageInterface[] | null => {
  if (districtSlug === District.Aachen) {
    return [
      {
        imgPath: '/sponsoring/sparkasse_westrhein.svg',
        sponsor: 'Sparkasse Aachen',
        link: 'https://www.sparkasse-aachen.de/de/home.html',
      },
    ];
  }

  if (districtSlug === District.Dueren) {
    return [
      {
        imgPath: '/sponsoring/sparkasse_westrhein.svg',
        sponsor: 'Sparkasse Düren',
        link: 'https://www.sparkasse-dueren.de/de/home.html',
      },
    ];
  }

  if (districtSlug === District.Heinsberg) {
    return [
      {
        imgPath: '/sponsoring/sparkasse_westrhein.svg',
        sponsor: 'Sparkasse Heinsberg',
        link: 'https://www.kreissparkasse-heinsberg.de/de/home.html',
      },
    ];
  }

  return null;
};
