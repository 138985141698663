import {useEffect, useRef} from 'react';
import {
  executeTrigger,
  useCommonNativeRatingDialogConditions,
  useDebounceCheck,
} from 'app/hooks/nativeRatingDialogTrigger/triggerNativeRatingDialog';
import {useNative} from 'app/contexts/NativeContext';

const useGalleryNRDTrigger = (contentLoaded, fullscreenOpen) => {
  const nativeInfo = useNative();
  const commonConditionUnfilled = useCommonNativeRatingDialogConditions(contentLoaded, nativeInfo);

  // use ref instead of component state to store variables as mutable values that exist for the lifetime of the page component
  const stateRef = useRef({commonConditionUnfilled, fullscreenOpen, requestedTrigger: false});

  const setRequestedTrigger = requestedTrigger => {
    stateRef.current = {...stateRef.current, requestedTrigger};
  };

  const checkTrigger = () => {
    const {fullscreenOpen, requestedTrigger, commonConditionUnfilled} = stateRef.current;
    //  - Prevent calling rating dialog twice
    //  - Prevent calling rating dialog if image is open as fullscreen
    if (fullscreenOpen || requestedTrigger || commonConditionUnfilled) {
      return;
    }

    const maxPageYOffset = window.document.body.clientHeight - window.innerHeight;
    // Special condition for gallery trigger:
    //  - Condition 3: User needs to scroll to end of page
    // Note: On android native: window.scrollY is a floating number
    // Compare with '===' not working correctly
    // Use tolerance value of -5px
    if (window.scrollY > maxPageYOffset - 5) {
      // Request rating dialog with a chance of 70%
      executeTrigger(0.7, setRequestedTrigger, 'gallery', nativeInfo);
    }
  };

  useDebounceCheck(commonConditionUnfilled, checkTrigger, 1500);

  useEffect(() => {
    stateRef.current = {...stateRef.current, fullscreenOpen};
  }, [fullscreenOpen]);

  useEffect(() => {
    stateRef.current = {...stateRef.current, commonConditionUnfilled};
  }, [commonConditionUnfilled]);
};

export {useGalleryNRDTrigger};
