import React, {useRef} from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, LoadingIndicator, LOADING_MESSAGE, Typography} from '@fupa/fupa-uikit';
import MatchHighlights from 'app/components/match/matchDetailHeader/MatchHighlights';
import {AppBarDesktopWrapper} from 'app/components/appBar/AppBarDesktopStyle';
import {useClickTracking} from 'app/hooks/useClickTracking';
import {numberWithThousandSeparator} from 'app/helpers/helpers';
import {TransitionGroup} from 'react-transition-group';
import MatchPenaltyShootout from 'app/components/match/matchDetailHeader/MatchPenaltyShootout';
import {FlexContainer} from 'app/styles/content';

const Container = styled(FlexContainer)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const MatchHeaderContent = styled.div`
  background-color: ${COLORS.white};
  width: inherit;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-width: ${props => props.theme.mobileContentWidth}px;
  margin: auto;
  ${props => props.theme.desktopLayout`
    margin-left: 0;
    max-width: ${props => props.theme.desktopContentWidth}px;
   `}
`;

const SideInformation = styled.div`
  width: 100%;
  padding: ${SIZES['16']} ${SIZES['16']} ${SIZES['8']} 0;
  text-align: right;
  height: ${SIZES[44]};
`;

const MatchHeaderDynamic = ({highlights, matchInfo, isFetching}) => {
  let displayHighlights;
  if (isFetching) {
    displayHighlights = <LoadingIndicator message={LOADING_MESSAGE.loadingHighlights} bgColor />;
  }
  if (highlights && matchInfo && matchInfo.homeTeam) {
    // START Workaround for penalty shootout highlights as long as no phase is available
    const penaltyShootoutHighlightsIndex = highlights.findIndex(highlight => highlight.subtype === 'penalty_shootout');
    const penaltyShootoutHighlights =
      penaltyShootoutHighlightsIndex > -1 ? highlights.slice(penaltyShootoutHighlightsIndex) : [];

    const regularHighlights =
      penaltyShootoutHighlightsIndex > -1 ? highlights.slice(0, penaltyShootoutHighlightsIndex) : highlights;
    let startTeamMiddleName = '';
    if (penaltyShootoutHighlights.length) {
      if (penaltyShootoutHighlights[0]?.team?.slug === matchInfo.homeTeam.slug) {
        startTeamMiddleName = matchInfo.homeTeam?.name?.middle ?? matchInfo?.homeTeamName ?? 'Heimteam';
      } else {
        startTeamMiddleName = matchInfo.awayTeam?.name?.middle ?? matchInfo?.awayTeamName ?? 'Gastteam';
      }
    }
    // END Workaround for penalty shootout highlights as long as no phase is available

    displayHighlights = (
      <Container>
        <TransitionGroup style={{width: 'inherit'}}>
          <MatchHighlights highlights={regularHighlights} homeTeamSlug={matchInfo.homeTeam.slug} />
          <MatchPenaltyShootout
            highlights={penaltyShootoutHighlights}
            startTeamMiddleName={startTeamMiddleName}
            homeTeamSlug={matchInfo.homeTeam.slug}
          />
        </TransitionGroup>
      </Container>
    );
  }
  const ref = useRef(null);

  const {clicks} = useClickTracking('match', matchInfo);

  return (
    <AppBarDesktopWrapper ref={ref}>
      <MatchHeaderContent>
        {displayHighlights}
        <SideInformation>
          <Typography variant='caption1'>{numberWithThousandSeparator(clicks)} Aufrufe</Typography>
        </SideInformation>
      </MatchHeaderContent>
    </AppBarDesktopWrapper>
  );
};

export default MatchHeaderDynamic;
