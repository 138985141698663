import React from 'react';
import {COLORS, Line, SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {round} from 'app/helpers/helpers';
import {LinkOnComponent} from 'app/components/links/link';

const MainContainer = styled(FlexContainer)`
  flex-direction: column;
  height: ${SIZES['42']};
`;

const DataContainer = styled(FlexContainer)`
  flex: 1 100%;
  height: ${SIZES['42']};
  display: flex;
  align-items: center;
  padding-right: ${SIZES['16']};
  padding-left: ${SIZES['12']};
  border-left: ${SIZES['4']} solid ${props => (props.clubColor === 'home' ? COLORS.fupaPrimary : COLORS.fupaSecondary)};
`;

const CustomName = styled(Typography)`
  flex: 1 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Stat = styled(Typography)`
  flex: 1 15%;
  text-align: center;
`;

const TrackticsPlayer = ({data, playerName, clubColor, playerSlug}) => {
  let sprints = '-';
  let speed = '-';
  let distance = '-';
  data.map(detail => {
    if (detail.key === 'sprints' && detail.value) {
      sprints = detail.value;
    }
    if (detail.key === 'speed' && detail.value) {
      speed = round(detail.value, 0);
    }
    if (detail.key === 'distance' && detail.value) {
      distance = detail.value.toFixed(1);
    }
  });

  return (
    <LinkOnComponent to={`/player/${playerSlug}`}>
      <MainContainer>
        <DataContainer clubColor={clubColor}>
          <CustomName variant='body1'>{playerName}</CustomName>
          <Stat variant='body1'>{sprints}</Stat>
          <Stat variant='body1'>{speed}</Stat>
          <Stat variant='body1'>{distance}</Stat>
        </DataContainer>
        <Line />
      </MainContainer>
    </LinkOnComponent>
  );
};

export default TrackticsPlayer;
