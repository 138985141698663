import {api} from 'app/services/api';
import {changeHeaderStyle, changeHeaderText} from 'app/actions/headerActions';
import {getCompetitionLinkPath} from 'app/helpers/actionsHelpers';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getNews = (slug, updatedAt) => {
  const newsSlug = slug.split('@')[0];
  let request = `${process.env.API_URL}v2/news/${newsSlug}`;
  request += updatedAt ? `?ts=${updatedAt}` : '';
  return api.get(request);
};

function fetchingNews() {
  return {
    type: 'NEWS_FETCHING',
  };
}

function updateNews(data) {
  return {
    type: 'NEWS_UPDATE',
    data,
  };
}

function setNewsHeader(dispatch, news) {
  let parent = null;
  const competition = news.links.competitions.length ? news.links.competitions[0].entity : null;
  if (competition) {
    const entity = getCompetitionLinkPath(competition);
    parent = {
      name: competition.middleName,
      entity,
      slugs: {
        [entity + 'Slug']: competition.slug,
      },
      variant: 'span',
    };
  }
  dispatch(
    changeHeaderText({
      title: news.category.description,
      variant: 'span',
      parent,
    })
  );
}

const trySettingDefaultDistrictFromNews = (author, publishDate, dispatch, getState) => {
  if (!author) {
    return;
  }
  const isResettedAuthor = !!(author && author.name === 'FuPa' && !author.district);
  if (isResettedAuthor) {
    return;
  }
  const isPre2021 = publishDate && new Date(publishDate) < new Date('2021-01-01') && !author.district;
  if (isPre2021) {
    return;
  }
  return trySettingDefaultDistrict(author, dispatch, getState);
};

const getErrorText = status => {
  if (status === 403) {
    return '…der aufgerufene Bericht ist nicht mehr verfügbar.';
  }
  if (status === 410) {
    return '…der aufgerufene Bericht wurde nicht gefunden.';
  }
  return null;
};

const handleDefaultHttpError = (dispatch, errorData) => {
  dispatch(updateHttpError({...errorData, text: '…der aufgerufene Bericht existiert nicht bzw. ist offline.'}));
};

function fetchNewsSSR(slug) {
  return async function (dispatch, getState) {
    dispatch(changeHeaderStyle({size: 'small'}));
    try {
      let response = await getNews(slug);
      const data = response.data;
      checkApiRedirect(data.slug, slug, null, null, getState);
      dispatch(
        updateHistory('undefined', {
          store: 'NewsPage',
          data: {data, isFetching: false},
        })
      );
      setNewsHeader(dispatch, data);
      // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
      return trySettingDefaultDistrictFromNews(data.author, data.publishDate, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      }
      const errorData = catchDataError(error);
      const errorText = getErrorText(errorData.status);
      if (errorText) {
        dispatch(
          updateHistory('undefined', {
            store: 'NewsPage',
            data: {
              data: {status: errorData.status, message: errorText},
              isFetching: false,
            },
          })
        );
      } else {
        handleDefaultHttpError(dispatch, errorData);
      }
    }
  };
}

function fetchNews(slug, updatedAt, dispatchRedux, forceRefresh, getState) {
  return function (dispatch, state) {
    if (!forceRefresh && (state.data?.slug || state.data?.status)) {
      return;
    }
    dispatchRedux(changeHeaderStyle({size: 'small'}));
    dispatch(fetchingNews());
    return getNews(slug, updatedAt)
      .then(response => {
        const data = response.data;
        checkApiRedirect(data.slug, slug);
        dispatch(updateNews(data));
        setNewsHeader(dispatchRedux, data);
        return trySettingDefaultDistrictFromNews(data.author, data.publishDate, dispatchRedux, getState);
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        }
        const errorData = catchDataError(error);
        const errorText = getErrorText(errorData.status);
        if (errorText) {
          dispatch(updateNews({status: errorData.status, message: errorText}));
        } else {
          handleDefaultHttpError(dispatchRedux, errorData);
        }
      });
  };
}

export {fetchNewsSSR, fetchNews};
