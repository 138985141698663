import {useEffect, useLayoutEffect, useRef} from 'react';
import debounce from 'app/helpers/debounce';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';

const checkScrollability = () => {
  if (typeof window === 'undefined') {
    return false;
  } else {
    return window.innerHeight < window.document.body.scrollHeight;
  }
};

const useCommonNativeRatingDialogConditions = (contentLoaded, nativeInfo) => {
  const isAuthenticated = useSelector(selectAuthState);
  // use ref instead of component state to store variables as mutable values that exist for the lifetime of the page component
  const stateRef = useRef({
    isScrollable: checkScrollability(),
    isAuthenticated,
    nativeApp: nativeInfo.isNativeApp,
  });

  useEffect(() => {
    stateRef.current = {...stateRef.current, isAuthenticated};
  }, [isAuthenticated]);

  useLayoutEffect(() => {
    if (contentLoaded) {
      stateRef.current = {...stateRef.current, isScrollable: checkScrollability()};
    }
  }, [contentLoaded]);

  // Common conditions handled for RatingDialog:
  //  - Condition 1: Call rating dialog only if user is authenticated
  //  - Condition 2: Call rating dialog only if user is using web view
  //  - Prevent calling rating dialog if content has no height to scroll
  return !stateRef.current.isAuthenticated || !stateRef.current.nativeApp || !stateRef.current.isScrollable;
};

const executeTrigger = (chance, setRequestedTrigger, type, nativeInfo) => {
  const num = Math.random();
  // Request rating dialog with a chance of x%
  if (num < chance) {
    nativeInfo.triggerRatingDialog.handler(type);
    setRequestedTrigger(true);
  }
};

const useDebounceCheck = (commonConditionUnfilled, checkTriggerFunc, wait) => {
  let viewportEvent;
  useEffect(() => {
    if (!commonConditionUnfilled) {
      // Need to execute effect only once for (un-)mount to be able to set value for wait of debounce higher than 250
      // Otherwise page re-renders to often and clears timeouts by updating hash and change location
      viewportEvent = debounce(checkTriggerFunc, wait);
      document.addEventListener('scroll', viewportEvent);
    }

    return () => {
      if (viewportEvent) {
        viewportEvent.cancel();
        document.removeEventListener('scroll', viewportEvent);
      }
    };
  }, [commonConditionUnfilled]);
};

const useCheckTriggerViewportPosition = (ratingTriggerRef, minPercentVP, contentLoaded, setIsInViewport) => {
  const checkTriggerViewportPosition = () => {
    const {top} = ratingTriggerRef.current.getBoundingClientRect();
    const threshold = window.innerHeight * minPercentVP;
    if (top <= threshold) {
      setIsInViewport(true);
      document.removeEventListener('scroll', checkTriggerViewportPosition);
    }
  };

  useLayoutEffect(() => {
    if (contentLoaded) {
      document.addEventListener('scroll', checkTriggerViewportPosition);
    }
    return () => {
      if (contentLoaded) {
        document.removeEventListener('scroll', checkTriggerViewportPosition);
      }
    };
  }, [contentLoaded]);
};

export {useCommonNativeRatingDialogConditions, executeTrigger, useDebounceCheck, useCheckTriggerViewportPosition};
