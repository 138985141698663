import React from 'react';
import SkyscraperAdSlot from 'app/components/ads/SkyscraperAdSlot';
import {Content, ContentAdWrapper} from 'app/styles/content';
import styled, {css} from 'styled-components';

const StyledContentAdWrapper = styled(ContentAdWrapper)`
  &&& {
    ${props =>
      props.hide &&
      css`
        display: none;
      `}
  }
`;

const ContentWrapper = styled.div`
  min-height: ${props => props.height / 16}rem;
`;

export const MatchSubrouteWrapper = ({hide, headerHeight, adPosition, contentWrapperHeight, children}) => {
  return (
    <StyledContentAdWrapper hide={hide}>
      <SkyscraperAdSlot initPosition={headerHeight + adPosition} skyscraperOnly={true} stickyThreshold={adPosition} />
      <SkyscraperAdSlot
        left
        initPosition={headerHeight + adPosition}
        skyscraperOnly={true}
        stickyThreshold={adPosition}
      />
      <ContentWrapper height={contentWrapperHeight}>
        <Content bgColor>{children}</Content>
      </ContentWrapper>
    </StyledContentAdWrapper>
  );
};
