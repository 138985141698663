import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {AdSlot} from 'app/components/ads/AdSlot';

const CONTENT_PADDING = 12;

const EdWBannerAdSlot = styled(AdSlot)`
  &&& {
    height: auto;
    width: 100%;
    max-width: 860px;
    max-height: 172px;
    margin: 0;
  }
`;

const EdWCenterAdSlot = styled(AdSlot)`
  &&& {
    border-radius: 50%;
    width: 5.5rem;
    height: 5.5rem;
    margin: 0;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: ${COLORS.white};
  //Aspect ratio 5:1
  width: 100%;
  height: calc((100vw - ${CONTENT_PADDING}px) / 5);
  aspect-ratio: 5/1;
  max-width: 860px;
  max-height: 172px;
  overflow: hidden;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 6.25rem;
  align-self: center;

  a {
    display: inherit;
    justify-content: inherit;
    align-content: center;
  }
`;

const CenterImageContainer = styled.div`
  width: ${SIZES['88']};
  height: ${SIZES['88']};
  background-color: ${COLORS.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SponsoringBannerTop = () => {
  return (
    <Container>
      <EdWBannerAdSlot adUnit='EdW_Banner_Top' />
    </Container>
  );
};

const SponsoringBannerBottom = () => {
  return (
    <Container>
      <EdWBannerAdSlot adUnit='EdW_Banner_Bottom' />
    </Container>
  );
};

const SponsoringCenter = () => {
  return (
    <CenterContainer>
      <CenterImageContainer>
        <EdWCenterAdSlot adUnit='EdW_Banner_Center' />
      </CenterImageContainer>
    </CenterContainer>
  );
};

export {SponsoringBannerTop, SponsoringBannerBottom, SponsoringCenter};
