import React from 'react';
import {styled} from '@mui/material/styles';
import {Snackbar} from '@mui/material';
import {SIZES} from '@fupa/fupa-uikit';
import {Alert as MuiAlert} from '@mui/material';

const SCSnackbar = styled(Snackbar)(({offsetTop}) => ({
  top: offsetTop ? `${offsetTop}` : SIZES[42],
}));

const Alert = ({autoHideDuration = 6000, onClose, open, offsetTop, text, variant = 'info'}) => {
  return (
    <SCSnackbar
      offsetTop={offsetTop}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}>
      <MuiAlert severity={variant}>{text}</MuiAlert>
    </SCSnackbar>
  );
};

export {Alert};
