import {Person} from '@mui/icons-material';
import {Button, COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0;
  align-items: center;
  white-space: pre-line;
  text-align: center;
`;

const ImagePlaceholder = styled.div`
  width: ${SIZES['64']};
  height: ${SIZES['64']};
  border-radius: ${SIZES['32']};
  background-color: ${COLORS.lightestGrey};
  margin-bottom: 0.5rem;
`;

const SCButton = styled(Button)`
  &&& {
    margin-top: 1rem;
  }
`;

const LineUpTeaser = ({matchId}) => {
  const handleClick = () => {
    window.open(
      `${process.env.ADMIN_URL}/fupa/admin/spielbericht.php?spiel=${matchId}&utm_source=fupa&utm_medium=teaser&utm_campaign=match_without_lineup#lineup-anchor`
    );
  };

  return (
    <Wrapper>
      <ImagePlaceholder>
        <Person style={{color: COLORS.lightGrey, fontSize: 64}} />
      </ImagePlaceholder>
      <Typography variant='body2'>
        Es ist noch keine Aufstellung eingetragen. Nur dann kann ein Spieler in die Elf der Woche gewählt werden.
      </Typography>
      <SCButton label='Aufstellung eintragen' onClick={handleClick} />
    </Wrapper>
  );
};

export {LineUpTeaser};
