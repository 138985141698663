import { useEffect } from 'react';
import { useNative } from 'app/contexts/NativeContext';
const taboolaLoaderScriptSrc = {
  web: 'fupaweb',
  ios: 'fupagmbh-appios',
  android: 'fupagmbh-androidapp'
};
const useTaboola = (enableTaboolaFeed, newsSlug) => {
  const {
    platform
  } = useNative();
  useEffect(() => {
    if (!enableTaboolaFeed) {
      return;
    }
    if (!document.getElementById('tb_loader_script')) {
      const script = document.createElement('script');
      script.async = true;
      script.src = '//cdn.taboola.com/libtrc/' + taboolaLoaderScriptSrc[platform] + '/loader.js';
      script.id = 'tb_loader_script';
      script.type = 'text/javascript';
      document.head.appendChild(script);
      if (window.performance && typeof window.performance.mark === 'function') {
        window.performance.mark('tbl_ic');
      }
    } else {
      window._taboola.push({
        notify: 'newPageLoad'
      });
    }
  }, [enableTaboolaFeed, newsSlug]);
  return null;
};
export default useTaboola;