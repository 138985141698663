import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import MatchDetailHeader from 'app/components/match/matchDetailHeader/MatchDetailHeader';
import styled from 'styled-components';
import MatchHeaderDynamic from 'app/components/match/matchDetailHeader/MatchHeaderDynamic';
import {COLORS, LoadingIndicator, SIZES} from '@fupa/fupa-uikit';
import MatchDetailAppBar from 'app/components/match/matchDetailHeader/MatchDetailAppBar';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {renderRoutes} from 'react-router-config';
import {
  fetchMatchMetaData,
  wsDeleteHighlights,
  wsUpdateHighlights,
  wsUpdateMatch,
} from 'app/routes/match/MatchPageActions';
import {TabBar} from 'app/components/tab/TabBar';
import {checkDesktopLayout} from 'app/styles/media';
import NotFoundPage from 'app/routes/error/NotFoundPage';
import {useLocation, useParams} from 'react-router';
import {MatchFab} from 'app/components/floatingAction/MatchFab';
import {useSiteSpecificAdTargeting} from 'app/components/ads/useAdTargeting';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';
import {selectSsrRendered, selectWebsocketData} from 'app/hooks/reduxCreateSelectorHooks';
import {useEffectAfterMount, useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {MatchMetaPageReducer} from 'app/routes/match/MatchPageReducer';
import {MatchSubrouteWrapper} from 'app/components/match/MatchSubrouteWrapper';
import {useWebSockets} from 'app/hooks/useWebSockets';
import {clearHistory} from 'app/actions/historyActions';
import {generateUniqueTemplateKey} from 'app/helpers/gtm';
import {useNative} from 'app/contexts/NativeContext';

const Wrapper = styled.div`
  position: relative;
  z-index: 30;
  width: 100%;
  background-color: ${COLORS.white};
  padding-top: env(safe-area-inset-top);
`;

const TabWrapper = styled.div`
  height: ${SIZES['42']};
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: calc(3.5rem + env(safe-area-inset-top));
  z-index: 30;
  width: 100%;

  ${props => props.theme.desktopLayout`
    top: ${SIZES['72']};
 `}
`;

const DesktopMatchFabButton = styled(MatchFab)`
  display: none;

  ${props => props.theme.desktopLayout`
    display: flex;
    position: absolute;
    margin-left: ${props.theme.desktopContentWidth - props.theme.fabWidth}px;
    margin-top:  -1.5rem;
  `};

  ${props => props.theme.desktopLayoutWithFab`
    margin-left: ${props.theme.desktopContentWidth}px;
  `};
`;

const matchPageUrlRegex = '^/match/:matchSlug(/(lineup|info|standing|duels))?$';

const getMetaInformation = (matchInfo, pathname) => {
  let homeTeamFullName, awayTeamFullName, competitionSeasonName, matchDate;
  if (Object.keys(matchInfo).length) {
    homeTeamFullName = matchInfo.homeTeamName;
    awayTeamFullName = matchInfo.awayTeamName;
    const homeTeam = matchInfo.homeTeam;
    const awayTeam = matchInfo.awayTeam;
    if (homeTeam && homeTeam.clubSlug !== 'dummy') {
      homeTeamFullName =
        homeTeam.level > 1 ? `${homeTeam.name.full} ${convertToRoman(homeTeam.level)}` : homeTeam.name.full;
    }
    if (awayTeam && awayTeam.clubSlug !== 'dummy') {
      awayTeamFullName =
        awayTeam.level > 1 ? `${awayTeam.name.full} ${convertToRoman(awayTeam.level)}` : awayTeam.name.full;
    }
    competitionSeasonName = matchInfo.competition?.name;
    const seasonName = !matchInfo.competition?.active ? matchInfo.competition?.season?.name : '';
    competitionSeasonName += seasonName ? ` ${seasonName}` : '';
    matchDate = formatShortDate(matchInfo.kickoff);
  }
  const matchDay = matchInfo?.round?.name ? `, ${matchInfo.round.name}` : '';
  const title = `Spielbericht ${homeTeamFullName} vs. ${awayTeamFullName} - ${competitionSeasonName}${matchDay}${TITLE_POSTFIX}`;
  const meta = [
    {
      name: 'description',
      content: `Alle Infos zum Spiel ${homeTeamFullName} gegen ${awayTeamFullName} am ${matchDate} - ${matchDay} - ${competitionSeasonName}`,
    },
    {
      name: 'robots',
      content: 'noindex, follow',
    },
    {
      property: 'og:title',
      content:
        typeof matchInfo.homeGoal === 'number' && typeof matchInfo.awayGoal === 'number'
          ? `${homeTeamFullName} - ${awayTeamFullName} ${matchInfo.homeGoal}:${matchInfo.awayGoal}`
          : `${homeTeamFullName} - ${awayTeamFullName} -:-`,
    },
    {property: 'og:description', content: competitionSeasonName},
    {property: 'og:image', content: `https://image.fupa.net/match/${matchInfo.slug}.png`},
    {property: 'og:image:secure_url', content: `https://image.fupa.net/match/${matchInfo.slug}.png`},
    {property: 'og:image:type', content: 'image/png'},
    {property: 'og:image:width', content: '1200'},
    {property: 'og:image:height', content: '630'},
    {property: 'og:type', content: 'article'},
    {property: 'og:site_name', content: 'FuPa'},
    {property: 'og:url', content: `https://www.fupa.net${pathname}`},
    {property: 'fb:app_id', content: '939336572887264'},
  ];

  return {title, meta};
};

const highlightHeight = 24;
const defaultDynamicHeight = 52; // MatchHeaderDynamic
const matchResultHeight = 140; // MatchDetailHeader - margin
const navBarHeight = 64;
const tabBarHeight = 42;
const fabButtonHeight = 48;
const fabButtonsExtended = 160;
const fixingOffset = 6;
let contentWrapperHeight;

const checkTickerStatus = (matchInfo, hasTicker) => {
  if (!hasTicker) return false;
  const curDate = new Date();
  const kickoffDate = new Date(matchInfo.kickoff);
  const fiveMinInMs = 300000;
  const twentyMinInMs = 1200000;
  const streamUpdatedAtInMs = matchInfo.streamUpdatedAt * 1000;
  const kickoffCurrDiff = kickoffDate.getTime() - curDate.getTime();
  const kickoffStartsIn5Min = kickoffCurrDiff < fiveMinInMs && kickoffCurrDiff > 0;
  const lastMatchEvent20MinAgo = Date.now() - streamUpdatedAtInMs < twentyMinInMs;
  return hasTicker && (kickoffStartsIn5Min || (lastMatchEvent20MinAgo && matchInfo.section === 'POST'));
};

const MatchPage = ({route}) => {
  const {restoredData} = useRestoreData('MatchPage');
  const dispatchRedux = useDispatch();
  const {pathname} = useLocation();
  const initData = {
    matchInfo: null,
    isFetching: false,
  };
  const initialState = restoredData ?? initData;
  const [state, dispatch] = useReducer(MatchMetaPageReducer, initialState);
  const {getState} = useStore();
  const {isFlutter} = useNative();

  const loadData = (forceRefresh, tsParam) =>
    fetchMatchMetaData(matchSlug, forceRefresh, dispatchRedux, tsParam, getState)(dispatch, state);

  const {matchInfo: matchInformation, isFetching} = state;
  const matchInfo = matchInformation ?? {};
  const hasTicker = matchInfo?.flags?.includes('ticker');
  const wsDefaultTrigger = hasTicker && matchInfo?.section === 'LIVE';
  const wsTickerTrigger = checkTickerStatus(matchInfo, hasTicker);
  const preventHistoryUpdate = !matchInformation?.kickoff || wsDefaultTrigger || wsTickerTrigger;
  useFetchData(state, loadData, 'MatchPage', false, false, preventHistoryUpdate);

  const wsPlaylistTrigger = matchInfo?.flags?.includes('fupatv') && matchInfo?.section !== 'POST';
  const conditionalTrigger = wsDefaultTrigger || wsPlaylistTrigger || wsTickerTrigger;
  const [subscribed, setSubscribed] = useState(false);
  const {websocketOpen, subscribeToChannel} = useWebSockets(dispatchRedux, conditionalTrigger);

  useEffect(() => {
    if (restoredData?.matchInfo?.section === 'LIVE') {
      dispatchRedux(clearHistory());
    }
  }, []);

  useEffect(() => {
    // Subscribe to channels of WS service
    if (websocketOpen && matchInfo?.id && !subscribed) {
      subscribeToChannel(`match.${matchInfo.id}`);
      setSubscribed(true);
    }
  }, [websocketOpen, matchInfo, subscribed]);

  const customHint = useMemo(() => {
    let custom_hint;
    const {template} = generateUniqueTemplateKey(pathname);
    if (matchInfo?.section && template === 'match_index') {
      const ws = websocketOpen ? '_WS' : '';
      custom_hint = `${matchInfo.section}${ws}`;
    }
    return custom_hint;
  }, [matchInfo, websocketOpen, pathname]);

  useSiteSpecificAdTargeting({custom_hint: customHint}, [customHint]);

  const highlightEvents = ['goal', 'card', 'penaltyfail', 'timepenalty'];
  const websocketData = useSelector(selectWebsocketData);
  useEffectAfterMount(() => {
    // Update components based on redux changes for store websocket
    if (websocketData?.event === 'liveticker.created') {
      dispatch(wsUpdateMatch(websocketData.data.entity));
      if (highlightEvents.includes(websocketData.data.entity.type)) {
        dispatch(wsUpdateHighlights(websocketData.data.entity, false));
      }
      if (websocketData.data.entity.type === 'whistle') {
        setWsWhistleEvent(prev => !prev);
      }
    } else if (websocketData?.event === 'liveticker.updated') {
      if (highlightEvents.includes(websocketData.data.entity.type)) {
        dispatch(wsUpdateHighlights(websocketData.data.entity, true));
      }
    } else if (websocketData?.event === 'liveticker.deleted') {
      dispatch(wsDeleteHighlights(websocketData.data.entity));
    }
  }, [websocketData]);

  const [appBarResult, setAppBarResult] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(matchResultHeight + defaultDynamicHeight);
  const [appBarHeight, setAppBarHeight] = useState(56);

  const ssrRendered = useSelector(selectSsrRendered);

  const highlights = matchInfo.highlights;
  const {matchSlug} = useParams();
  const urlRegex = new RegExp(matchPageUrlRegex.replace(':matchSlug', matchSlug));

  const {meta, title} = matchInfo ? getMetaInformation(matchInfo, pathname) : {meta: null, title: ''};
  const [adPosition, setAdPosition] = useState(appBarHeight + tabBarHeight + fabButtonHeight);
  const [wsWhistleEvent, setWsWhistleEvent] = useState(false);
  const location = useLocation();

  // Request header data on tab navigation
  useEffectAfterMount(() => loadData(true), [location.pathname]);

  // Request header data on ws whistle event with nocache
  useEffectAfterMount(() => {
    const ts = Math.floor(new Date().getTime() / 1000);
    loadData(true, ts);
  }, [wsWhistleEvent]);

  const club = [matchInfo?.homeTeam?.clubSlug, matchInfo?.awayTeam?.clubSlug];
  const competition = matchInfo?.competition?.slug;
  useSiteSpecificAdTargeting({club, competition}, [matchInfo?.homeTeam, matchInfo?.awayTeam, matchInfo?.competition]);

  useEffect(() => {
    if (isFlutter) {
      window.handleNativeResume = () => {
        window.location.reload();
      };
    }
    return () => {
      if (isFlutter) {
        window.handleNativeResume = () => {};
      }
    };
  }, []);

  const TabNames = [
    {
      name: 'Ticker',
      url: `/match/${matchSlug}`,
    },
    {
      name: 'Aufstellung',
      url: `/match/${matchSlug}/lineup`,
      rel: 'nofollow',
    },
    {
      name: 'Info',
      url: `/match/${matchSlug}/info`,
      rel: 'nofollow',
    },
    {
      name: 'Tabelle',
      url: `/match/${matchSlug}/standing`,
      rel: 'nofollow',
    },
    {
      name: 'Duelle',
      url: `/match/${matchSlug}/duels`,
      rel: 'nofollow',
    },
  ];

  const handleMatchChange = updateFunc => {
    dispatch(updateFunc());
  };

  const handleResize = () => {
    updateAppBarHeight();
    calcHeaderHeight();
  };

  const updateAppBarHeight = () => {
    if (checkDesktopLayout()) {
      setAppBarHeight(72);
    } else {
      setAppBarHeight(56);
    }
  };

  useEffect(() => {
    updateAppBarHeight();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationChange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationChange', handleResize);
    };
  });

  useEffect(() => {
    if (ssrRendered) {
      calcHeaderHeight();
    }
    contentWrapperHeight = window.innerHeight - appBarHeight - tabBarHeight - navBarHeight - fixingOffset;
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY >= appBarHeight && !appBarResult) {
        setAppBarResult(true);
      } else if (window.scrollY < appBarHeight && appBarResult) {
        setAppBarResult(false);
      }
    };

    document.addEventListener('scroll', onScroll, {passive: true});
    return () => {
      document.removeEventListener('scroll', onScroll, {passive: true});
    };
  }, [appBarHeight, appBarResult]);

  useEffect(() => {
    calcHeaderHeight();
  }, [highlights]);

  const handleFabToggle = extended => {
    setAdPosition(extended ? adPosition + fabButtonsExtended : adPosition - fabButtonsExtended);
  };

  const calcHeaderHeight = () => {
    const additionalPadding = checkDesktopLayout() ? 16 : 0;
    const highlightsCount = highlights ? highlights.length : 0;
    const highlightsHeight = highlightsCount * highlightHeight;
    const newHeaderHeight = highlightsHeight + defaultDynamicHeight + matchResultHeight + additionalPadding;
    setHeaderHeight(newHeaderHeight);
  };

  if (!urlRegex.test(pathname)) {
    return <NotFoundPage id='not-found-page' />;
  }

  const header = !matchInformation ? (
    <LoadingIndicator bgColor={true} message='Spieldaten werden geladen...' />
  ) : (
    <>
      <Wrapper>
        <MatchDetailAppBar matchInfo={matchInfo} appBarResult={appBarResult} title={title} />
        <MatchDetailHeader matchInfo={matchInfo} />
        <MatchHeaderDynamic isFetching={isFetching} highlights={highlights} matchInfo={matchInfo} />
      </Wrapper>
      <TabWrapper>
        <TabBar tabs={TabNames} withBg replace disableHeaderCollapsing>
          <DesktopMatchFabButton text={title} currentEntity={matchInfo} onToggle={handleFabToggle} />
        </TabBar>
      </TabWrapper>
    </>
  );

  return (
    <>
      <Helmet title={title} meta={meta} />
      {header}
      <MatchSubrouteWrapper
        hide={!matchInformation}
        headerHeight={headerHeight}
        adPosition={adPosition}
        contentWrapperHeight={contentWrapperHeight}>
        {renderRoutes(route.routes, {
          title,
          matchInfo,
          loadDataMatchMeta: loadData,
          preventHistoryUpdate,
          matchInfoIsFetching: isFetching,
          onMatchChange: handleMatchChange,
        })}
      </MatchSubrouteWrapper>
      <StickyMobileBannerAdSlot />
    </>
  );
};

export default MatchPage;
