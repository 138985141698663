const NewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'NEWS_UPDATE':
      return {...state, data: action.data, isFetching: false};
    default:
      return state;
  }
};

export {NewsPageReducer};
