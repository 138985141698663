import React from 'react';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {Back} from 'app/components/appBar/back';
import {AppBarActionsShareAndEdit} from 'app/components/appBar/AppBarActionsShareAndEdit';
import {SocialShareButtons} from 'app/components/socialShare/socialShareButtons';
import {useShare} from 'app/components/socialShare/useShare';
import {windowIsDefined} from 'app/helpers/windowIsDefined';

const SmallHeaderBar = styled.div`
  width: 100%;
  position: fixed;
  top: env(safe-area-inset-top);
  box-sizing: content-box;
  display: flex;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
  height: ${SIZES['56']};
  justify-content: space-between;
  align-items: center;
  z-index: 30;
  max-width: ${props => props.theme.desktopContentWidth}px;
  ${props => props.theme.desktopLayout`
    display: none;
  `}
`;

const BackWrapper = styled.div`
  width: ${SIZES['72']};
`;

const NewsAppBar = ({title, editUrl, isAuthorised, newsSlug}) => {
  const url = windowIsDefined ? window.location.href : '';
  const eventCat = 'news';
  const eventSlug = newsSlug;
  const {open, handleShare, handleShareClose} = useShare(eventCat, eventSlug, title);

  const handleEdit = () => {
    window.open(editUrl);
  };

  return (
    <SmallHeaderBar>
      <BackWrapper>
        <Back color={COLORS.white} />
      </BackWrapper>
      <AppBarActionsShareAndEdit
        color={COLORS.white}
        handleEdit={handleEdit}
        handleShare={handleShare}
        isEditRequired={isAuthorised}
      />
      <SocialShareButtons
        url={url}
        text={title}
        open={open}
        onClose={handleShareClose}
        eventCat={eventCat}
        eventSlug={eventSlug}
      />
    </SmallHeaderBar>
  );
};

export {NewsAppBar};
