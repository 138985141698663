import React from 'react';
import {IconButton} from '@mui/material';
import {Edit, Share} from '@mui/icons-material';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.color};
  min-width: ${SIZES[72]};
`;

const AppBarActionsShareAndEdit = ({handleShare, handleEdit, color = COLORS.darkGrey, className, isEditRequired}) => {
  const editBtn = isEditRequired ? (
    <IconButton color='inherit' aria-controls='simple-menu' sx={{paddingLeft: 0}} onClick={handleEdit} size='large'>
      <Edit color='inherit' />
    </IconButton>
  ) : null;

  return (
    <Actions className={className} color={color}>
      {editBtn}
      <IconButton color='inherit' aria-controls='simple-menu' sx={{paddingLeft: 0}} onClick={handleShare} size='large'>
        <Share color='inherit' />
      </IconButton>
    </Actions>
  );
};

export {AppBarActionsShareAndEdit};
