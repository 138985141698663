import React from 'react';
import {Subheading} from 'app/styles/heading';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import LineUp from 'app/components/LineUp/LineUp';
import StartingAvgAge from 'app/components/match/matchData/StartingAvgAge';
import {AdSlot} from 'app/components/ads/AdSlot';
import {LineUpTeaser} from 'app/components/LineUp/LineUpTeaser';

const Team = styled.div`
  flex: 1 100%;
  padding: 0 ${SIZES['16']};
`;

const MainContainer = styled.div`
  padding-bottom: ${SIZES['14']};
`;

const SCTypography = styled(Typography)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MatchLineUps = ({team, averageAge, matchId}) => {
  const substitutesPlaceholder = 'Keine Auswechselspieler eingetragen.';
  const coachPlaceholder = 'Kein Trainer eingetragen.';
  let startingXI = <LineUpTeaser matchId={matchId} />;
  let substitutes = <SCTypography variant='body2'>{substitutesPlaceholder}</SCTypography>;
  let coach = <SCTypography variant='body2'>{coachPlaceholder}</SCTypography>;

  const teamFacts = {
    avgAge: averageAge,
    startingXI: team.filter(player => player.starting),
    substitutes: team.filter(player => !player.starting && !player.coach),
    coach: team.filter(player => player.coach),
  };

  const adUnit = teamFacts.startingXI.length ? <AdSlot key='Content_1' adUnit='Content_1' /> : null;
  startingXI = teamFacts.startingXI.length ? (
    <div>
      <StartingAvgAge caption='Alter:' age={teamFacts.avgAge} />
      <LineUp lineup={teamFacts.startingXI} />
    </div>
  ) : (
    startingXI
  );

  substitutes = teamFacts.substitutes.length ? <LineUp lineup={teamFacts.substitutes} /> : substitutes;

  coach = teamFacts.coach.length ? <LineUp lineup={teamFacts.coach} type='coach' /> : coach;

  return (
    <MainContainer>
      <FlexContainer>
        <Team>{startingXI}</Team>
      </FlexContainer>
      {adUnit}
      <Subheading variant='subheading2'>Bank</Subheading>
      <FlexContainer>
        <Team>{substitutes}</Team>
      </FlexContainer>
      <Subheading variant='subheading2'>Trainer</Subheading>
      <FlexContainer>
        <Team>{coach}</Team>
      </FlexContainer>
    </MainContainer>
  );
};

export default MatchLineUps;
