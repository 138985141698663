import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';

const Goal = styled.div`
  display: flex;
  align-items: flex-end;
  width: 3rem;
  height: 3.875rem;
`;

const HomeGoal = styled(Goal)`
  justify-content: flex-end;
`;
const AwayGoal = styled(Goal)`
  justify-content: flex-start;
`;

const MatchScoreContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  width: 6.75rem;
`;

const MatchScore = styled(FlexContainer)`
  letter-spacing: 0.21875rem;
  height: ${SIZES['64']};
  align-items: flex-end;
`;

export {HomeGoal, AwayGoal, MatchScoreContainer, MatchScore};
