import React from 'react';
import {formatLongDate} from 'app/helpers/dateHelpers';
import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';
import {FPHTMLLink} from 'app/components/links/link';

const AdditionalInformation = styled.div`
  padding: ${SIZES['16']} ${SIZES['20']};
`;

const Info = styled(FlexContainer)`
  flex-direction: column;
  padding-bottom: ${SIZES['8']};
`;

const CustomTypography = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

const Link = styled(FPHTMLLink)`
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: ${COLORS.fupaSecondary};
  }
`;

const TopElevenInformation = ({topeleven}) => {
  let substitutes = [];
  let failedTeams = [];

  if (Object.keys(topeleven).length) {
    topeleven.substitutes.map(player =>
      player.player
        ? substitutes.push(`${player.player.firstName} ${player.player.lastName} (${player.position})`)
        : null
    );
    topeleven.failedTeams.map(team => failedTeams.push(team.name.full));
  }

  const checkSubstitutes = substitutes.length ? substitutes.join(', ') : '-';
  const checkFailedTeams = failedTeams.length ? failedTeams.join(', ') : '-';

  return (
    <AdditionalInformation>
      <Info>
        <CustomTypography variant='caption1'>Knapp gescheitert</CustomTypography>
        <CustomTypography variant='body1'>{checkSubstitutes}</CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Wie kommt die Elf der Woche zustande?</CustomTypography>
        <CustomTypography variant='body1'>
          <Link
            target='_blank'
            href='https://support.fupa.net/support/solutions/articles/75000014577-kriterien-faktoren-f%C3%BCr-elf-der-woche-'>
            👉 Hier findest Du alle Informationen
          </Link>
        </CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Auswertung am</CustomTypography>
        <CustomTypography variant='body1'>{formatLongDate(topeleven.date)} Uhr</CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Abstimmungen gesamt</CustomTypography>
        <CustomTypography variant='body1'>{topeleven.votes}</CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Keine Auswertung</CustomTypography>
        <CustomTypography variant='body1'>{checkFailedTeams}</CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Elf des Jahres</CustomTypography>
        <CustomTypography variant='body1'>
          <Link target='_blank' href=' https://11desjahres.fupa.net/'>
            👉 Hier geht's zur Elf des Jahres
          </Link>
        </CustomTypography>
      </Info>
      <Info>
        <CustomTypography variant='caption1'>Partnerschaft</CustomTypography>
        <CustomTypography variant='body1'>
          <Link target='_blank' href='https://www.interwetten.com/de/sportwetten'>
            👉 Interwetten ist offizieller Partner von FuPa
          </Link>
        </CustomTypography>
      </Info>
    </AdditionalInformation>
  );
};

export default TopElevenInformation;
