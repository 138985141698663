import React from 'react';
import {FlexContainer} from 'app/styles/content';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import {getPlayerShortName} from 'app/helpers/helpers';
import {Football, PenaltyMissed} from 'app/components/icons';
import MatchDetailEventSummary from 'app/components/match/matchDetailHeader/MatchDetailEventSummary';
import {Collapse} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import {
  AwayHighlight,
  HomeHighlight,
  Name,
  Event,
  ElementContainer,
} from 'app/components/match/matchDetailHeader/MatchDetailEventSummaryStyles';

const PenaltyShootoutHighlight = ({highlight, homeTeam}) => {
  let icon = <Football style={{color: COLORS.darkerGrey, fontSize: 12}} />;
  let name = highlight.primaryRole?.lastName ? getPlayerShortName(highlight.primaryRole) : 'Unbekannt';
  const isPenaltyFail = highlight.type === 'penaltyfail';

  switch (highlight.subtype) {
    case 'penalty_shootout':
    case 'penalty_foul':
    case 'penalty_hand':
      const color = isPenaltyFail ? COLORS.neonRed : COLORS.green;

      icon = isPenaltyFail ? <PenaltyMissed style={{fontSize: 12}} /> : <Football style={{color, fontSize: 12}} />;
      break;
  }

  const homeGoal = homeTeam && !isPenaltyFail ? <strong>{highlight.homeGoal}</strong> : highlight.homeGoal;
  const awayGoal = homeTeam || isPenaltyFail ? highlight.awayGoal : <strong>{highlight.awayGoal}</strong>;

  const content = (
    <>
      <Name>
        <Typography variant='caption1'>{name}</Typography>
      </Name>
      <Event>
        <Typography variant='caption1'>{homeGoal}</Typography>
        <Typography variant='caption1'>:</Typography>
        <Typography variant='caption1'>{awayGoal}</Typography>
      </Event>
      <ElementContainer home>{icon}</ElementContainer>
    </>
  );
  return homeTeam ? <HomeHighlight>{content}</HomeHighlight> : <AwayHighlight>{content}</AwayHighlight>;
};

const MatchPenaltyShootout = ({homeTeamSlug, highlights, startTeamMiddleName}) => {
  const penaltyShootoutHighlights = highlights;

  if (!penaltyShootoutHighlights.length) {
    return null;
  }

  const teamStartsMessage = `${startTeamMiddleName} beginnt`;

  const homeTeamPenaltyShootoutHighlights = penaltyShootoutHighlights.filter(highlight => {
    if (highlight.team?.slug === homeTeamSlug) {
      return highlight;
    }
  });
  const awayTeamPenaltyShootoutHighlights = penaltyShootoutHighlights.filter(highlight => {
    if (highlight.team?.slug !== homeTeamSlug) {
      return highlight;
    }
  });

  const displayedPenaltyShootoutHighlightsHome = homeTeamPenaltyShootoutHighlights.map((highlight, index) =>
    highlight.team ? (
      <Collapse id={highlight.id}>
        <PenaltyShootoutHighlight
          homeTeam={true}
          index={index}
          key={highlight.id}
          highlight={highlight}
          homeTeamSlug={homeTeamSlug}
        />
      </Collapse>
    ) : null
  );
  const displayedPenaltyShootoutHighlightsAway = awayTeamPenaltyShootoutHighlights.map((highlight, index) =>
    highlight.team ? (
      <Collapse id={highlight.id}>
        <PenaltyShootoutHighlight
          homeTeam={false}
          index={index}
          key={highlight.id}
          highlight={highlight}
          homeTeamSlug={homeTeamSlug}
        />
      </Collapse>
    ) : null
  );

  return (
    <MatchDetailEventSummary label={'Elfmeterschießen'} caption={teamStartsMessage}>
      <FlexContainer style={{flex: 100}}>
        <TransitionGroup style={{flex: '50%'}}>{displayedPenaltyShootoutHighlightsHome}</TransitionGroup>
        <TransitionGroup style={{flex: '50%'}}>{displayedPenaltyShootoutHighlightsAway}</TransitionGroup>
      </FlexContainer>
    </MatchDetailEventSummary>
  );
};

export default MatchPenaltyShootout;
