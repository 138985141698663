import {useEffect, useRef} from 'react';
import {
  executeTrigger,
  useCheckTriggerViewportPosition,
  useCommonNativeRatingDialogConditions,
  useDebounceCheck,
} from 'app/hooks/nativeRatingDialogTrigger/triggerNativeRatingDialog';
import {useNative} from 'app/contexts/NativeContext';

const useNewsNRDTrigger = (contentLoaded, ratingTriggerRef) => {
  const nativeInfo = useNative();
  const commonConditionUnfilled = useCommonNativeRatingDialogConditions(contentLoaded, nativeInfo);

  // use ref instead of component state to store variables as mutable values that exist for the lifetime of the page component
  const stateRef = useRef({
    commonConditionUnfilled,
    requestedTrigger: false,
    isInViewport: false,
  });

  const setRequestedTrigger = requestedTrigger => {
    stateRef.current = {...stateRef.current, requestedTrigger};
  };

  const setIsInViewport = isInViewport => {
    stateRef.current = {...stateRef.current, isInViewport};
  };

  const checkTrigger = () => {
    const {requestedTrigger, isInViewport, commonConditionUnfilled} = stateRef.current;
    // Condition 3: Call rating dialog only if user has scrolled NewsLinkings over 30% into viewport
    // Prevent calling rating dialog twice
    if (commonConditionUnfilled || requestedTrigger || !isInViewport) {
      return;
    }
    executeTrigger(0.4, setRequestedTrigger, 'news', nativeInfo);
  };

  useDebounceCheck(commonConditionUnfilled, checkTrigger, 1500);
  useCheckTriggerViewportPosition(ratingTriggerRef, 0.7, contentLoaded, setIsInViewport);

  useEffect(() => {
    stateRef.current = {...stateRef.current, commonConditionUnfilled};
  }, [commonConditionUnfilled]);
};

export {useNewsNRDTrigger};
