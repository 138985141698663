import {useEffect} from 'react';
import {useHistory} from 'react-router';
import queryString from 'query-string';

const PARAM_NAME = 'dialog';

export const useMarkOpenDialog = (open: boolean, value: string) => {
  const history = useHistory();

  useEffect(() => {
    const searchContainsValue = checkSearchContainsValue(value);
    if (open && !searchContainsValue) {
      addSearchParam(value);
      return;
    }
    if (!open && searchContainsValue) {
      removeSearchParam(value);
      return;
    }
  }, [open, value]);

  const getCurrentSearch = () => {
    return history.location.search;
  };

  const checkSearchContainsValue = (value: string) => {
    const currentSearch = getCurrentSearch();
    const searchParams = queryString.parse(currentSearch);
    return !!searchParams.dialog?.includes(value);
  };

  const addSearchParam = (value: string) => {
    const currentSearch = getCurrentSearch();
    const newSearch = currentSearch ? `${currentSearch}&${PARAM_NAME}=${value}` : `?${PARAM_NAME}=${value}`;
    history.replace({search: newSearch});
  };

  const removeSearchParam = (value: string) => {
    const currentSearch = getCurrentSearch();
    const newSearch = currentSearch.replace(new RegExp(`[&?]${PARAM_NAME}=${value}`), '');
    history.replace({search: newSearch});
  };
};
