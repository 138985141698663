const LeagueTopElevenPageReducer = (state, action) => {
  switch (action.type) {
    case 'LEAGUE_TOPELEVEN_META_LEAGUE_UPDATE':
      return {...state, metaLeague: action.data};
    case 'LEAGUE_TOPELEVEN_FETCHING':
      return {...state, isFetching: true};
    case 'LEAGUE_TOPELEVEN_UPDATE':
      return {...state, topelevenContent: action.data, isFetching: false};
    default:
      return state;
  }
};

export {LeagueTopElevenPageReducer};
