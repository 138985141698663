import React, {useMemo, useState} from 'react';
import {COLORS, SIZES, Image, Typography, FONTS} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {Back} from 'app/components/appBar/back';
import MatchResult from 'app/components/matchRow/MatchResult';
import {generateMatchCompetitionInfo} from 'app/helpers/actionsHelpers';
import {MatchPushButton} from 'app/components/match/MatchPushButton';
import {MoreVert, Edit, Share, PlayCircle} from '@mui/icons-material';
import {IconButton, MenuItem, Menu, ListItemIcon, ListItemText} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {AppBarActionsShareAndEdit} from 'app/components/appBar/AppBarActionsShareAndEdit';
import {SocialShareButtons} from 'app/components/socialShare/socialShareButtons';
import {useShare} from 'app/components/socialShare/useShare';
import {openDeepLink, useSportVideoDeepLink} from 'app/components/match/SportVideoDeepLink';
import {LoadingButton} from '@mui/lab';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {MatchResultModel} from 'app/models/match/MatchResultModel';

const AppBarActionsShareAndEditSC = styled(AppBarActionsShareAndEdit)`
  width: ${SIZES['72']};
`;

const SmallHeaderBar = styled.div`
  position: fixed;
  top: env(safe-area-inset-top);
  box-sizing: content-box;
  display: flex;
  width: inherit;
  background-color: ${COLORS.white};
  height: ${SIZES['56']};
  justify-content: space-between;
  align-items: center;
  z-index: 60;
  ${props => props.theme.desktopLayout`
    display: none;
  `}

  .invert {
    > div {
      & span {
        color: ${COLORS.white};
      }
    }
  }
`;

const BackgroundImages = styled(SmallHeaderBar)`
  visibility: ${props => (props.appBarResult ? 'visible' : 'hidden')};
  z-index: -1;
  background-image: url(${props => props.homeTeamImage}), url(${props => props.awayTeamImage});
  background-position: left center, right center;
  background-repeat: no-repeat;
  background-size: 50%, 50%;
  -webkit-filter: opacity(0.2) grayscale(0.1);
  filter: opacity(0.08) grayscale(0.1);
`;

const InfoColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: ${SIZES['72']};
`;

const BackWrapper = styled.div`
  width: ${SIZES['72']};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: inherit;
  position: relative;
`;

const AppBarMatchRow = styled(InfoColumn)`
  position: absolute;
  visibility: ${props => (props.appBarResult ? 'visible' : 'hidden')};
  gap: 0.75rem;
`;

const AppBarCaption = styled(Typography)`
  &&& {
    text-align: center;
    display: inline-flex;
    position: ${props =>
      props.appBarResult
        ? 'absolute'
        : 'relative'}; //need to change position conditionally otherwise it would be rendered on the right top corner and jumps down
    visibility: ${props => (!props.appBarResult ? 'visible' : 'hidden')};
    font-weight: ${FONTS.bold};

    & a {
      display: flex;
      min-height: 1.5rem;
      border-radius: 1rem;
      background-color: ${COLORS.lightestGrey};
      align-items: center;
      padding: 0 0.75rem;
    }
  }
`;

const MatchDetailAppBar = ({matchInfo, appBarResult, title}) => {
  let competitionInfo, appBarMatchRow, appBarCaption, ActionButtons;
  const isAuthenticated = useSelector(selectAuthState);
  const isVideoAllowed = matchInfo?.competition?.videoAllowed;
  const {generateDeepLink, deepLink, isLoading} = useSportVideoDeepLink();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const eventCat = 'match';
  const eventSlug = matchInfo?.slug;
  const {open, handleShare, handleShareClose} = useShare(eventCat, eventSlug, title, handleMenuClose);
  const url = windowIsDefined ? window.location.href : '';
  const matchResult = useMemo(() => new MatchResultModel(matchInfo), [matchInfo]);

  const handleMoreClick = event => {
    setAnchorEl(event.currentTarget);
    if (!deepLink) {
      generateDeepLink(matchInfo);
    }
  };

  const handleEdit = () => {
    window.open(`${process.env.ADMIN_URL}/fupa/admin/spielbericht.php?spiel=${matchInfo.id}`);
  };

  const handleVideo = () => {
    openDeepLink(deepLink);
  };
  const homeTeamImage = matchInfo?.homeTeam ? matchInfo.homeTeam.image : null;
  const awayTeamImage = matchInfo?.awayTeam ? matchInfo.awayTeam.image : null;

  let homeTeamBackgroundSrc, awayTeamBackgroundSrc, invertResult;
  if (matchInfo?.homeTeam?.image?.path && matchInfo?.awayTeam?.image?.path) {
    homeTeamBackgroundSrc = matchInfo.homeTeam?.image?.svg
      ? matchInfo.homeTeam.image.path + 'image.svg'
      : `${matchInfo.homeTeam.image.path}/300x300.png`;
    awayTeamBackgroundSrc = matchInfo.awayTeam?.image?.svg
      ? matchInfo.awayTeam.image.path + 'image.svg'
      : `${matchInfo.awayTeam.image.path}/300x300.png`;
    invertResult = 'invert';
  }

  if (matchInfo) {
    competitionInfo = generateMatchCompetitionInfo(matchInfo);

    // Need to render both Content Types to prevent fetching Images on every change of displayed appBar
    appBarMatchRow = (
      <AppBarMatchRow appBarResult={appBarResult}>
        <Image entity='emblem' src={homeTeamImage} width={32} height={32} fit={true} />
        <MatchResult className={invertResult} color={COLORS.darkerGrey} matchResult={matchResult} allowLive={false} />
        <Image entity='emblem' src={awayTeamImage} width={32} height={32} fit={true} />
      </AppBarMatchRow>
    );

    appBarCaption = (
      <AppBarCaption variant='caption1' appBarResult={appBarResult}>
        {competitionInfo}
      </AppBarCaption>
    );
  }

  // Don't show NotificationButton after the match
  if (matchInfo.section !== 'POST') {
    ActionButtons = (
      <Actions>
        <MatchPushButton currentEntityId={matchInfo.id} sx={{paddingLeft: 0}} component={IconButton} />
        <IconButton aria-controls='simple-menu' sx={{paddingLeft: 0}} onClick={handleMoreClick} size='large'>
          <MoreVert />
        </IconButton>
        <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleShare}>
            <ListItemIcon sx={{minWidth: '2.25rem'}}>
              <Share />
            </ListItemIcon>
            <ListItemText primary='Teilen' />
          </MenuItem>
          {isVideoAllowed ? (
            <MenuItem
              as={LoadingButton}
              sx={{font: 'initial', color: 'initial', textTransform: 'initial'}}
              loading={isLoading}
              disabled={!deepLink}
              onClick={handleVideo}
            >
              <ListItemIcon sx={{minWidth: '2.25rem'}}>
                <PlayCircle />
              </ListItemIcon>
              <ListItemText primary='Filmen mit Sport.Video' />
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem onClick={handleEdit}>
              <ListItemIcon sx={{minWidth: '2.25rem'}}>
                <Edit />
              </ListItemIcon>
              <ListItemText primary='Bearbeiten' />
            </MenuItem>
          ) : null}
        </Menu>
      </Actions>
    );
  } else {
    ActionButtons = (
      <AppBarActionsShareAndEditSC handleShare={handleShare} handleEdit={handleEdit} isEditRequired={isAuthenticated} />
    );
  }

  return (
    <SmallHeaderBar>
      <BackgroundImages
        appBarResult={appBarResult}
        homeTeamImage={homeTeamBackgroundSrc}
        awayTeamImage={awayTeamBackgroundSrc}
      />
      <BackWrapper>
        <Back color={COLORS.darkGrey} />
      </BackWrapper>
      <Content>
        {appBarMatchRow}
        {appBarCaption}
      </Content>
      {ActionButtons}
      <SocialShareButtons
        url={url}
        text={title}
        open={open}
        onClose={handleShareClose}
        eventSlug={eventSlug}
        eventCat={eventCat}
      />
    </SmallHeaderBar>
  );
};

export default MatchDetailAppBar;
