import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {captureSentryException} from 'app/services/sentryLogging';

export const getUTMLink = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const pathParams = window.location.pathname.split('/');
  pathParams.shift();
  const path1 = pathParams[0];
  let entity = path1;
  const search = window.location.search;
  let queryParam = '?';
  let utm = 'utm_source=fupa&utm_medium=sharebutton&utm_campaign=share_';
  if (path1 === 'league' && pathParams.includes('topeleven')) {
    entity = 'topeleven';
  }
  if (path1 === 'tv') {
    entity = 'video';
  }
  if (search) {
    if (search.includes('utm')) {
      utm = '';
      queryParam = '';
    } else if (path1 === 'match' && search.includes('voting')) {
      entity = 'matchvoting';
    } else {
      queryParam = '&';
    }
  }
  return queryParam + utm + entity;
};

const openNativeShareDialog = async (text, url) => {
  try {
    return await navigator.share({title: 'FuPa', text, url});
  } catch (error) {
    if (!error.toString().includes('AbortError')) {
      captureSentryException(error, {extra: {errorLocation: 'openNativeShareDialog'}});
    }
  }
};

export const openMobileShareDialog = (title, eventCat, eventSlug, nativeInfo) => {
  const {isNativeApp, shareUrl, platform} = nativeInfo;
  const isIos = platform === 'ios';
  const isAndroid = platform === 'android';
  const utmLink = getUTMLink();
  const method = isAndroid ? 'android_native' : isIos ? 'ios_native' : 'web_native';
  if (isNativeApp) {
    shareUrl.handler(window.location.href + utmLink);
    // ToDo: Send GA4 event via native wrapper to firebase analytics
    sendEventToGA4('share', {item_id: eventSlug, content_type: eventCat, method});
    return false;
  } else if (navigator.share) {
    openNativeShareDialog(title, window.location.href + utmLink);
    sendEventToGA4('share', {item_id: eventSlug, content_type: eventCat, method});
    return false;
  } else {
    return true;
  }
};
