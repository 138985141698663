import React, {useState} from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import styled from 'styled-components';
import {Dialog} from 'app/components/dialog/Dialog';
import {getUTMLink} from 'app/helpers/openShareDialog';
import {TextField, Tooltip} from '@mui/material';
import {ContentCopy} from '@mui/icons-material';
import {updateSnackbarInfo} from 'app/components/snackbar/SnackbarActions';
import {useDispatch} from 'react-redux';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {windowIsDefined} from 'app/helpers/windowIsDefined';
import {captureSentryException} from 'app/services/sentryLogging';

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIZES['32']};
  height: ${SIZES['32']};
  border-radius: ${SIZES['16']};
  background-color: ${COLORS.darkGrey};
`;

const Label = styled.span`
  padding-left: 0.75rem;
`;

const SocialShareButtons = ({url, text, onClose, open, ...props}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  url += getUTMLink();
  const dispatch = useDispatch();
  const handleShareSuccess = method => {
    sendEventToGA4('share', {item_id: props.eventSlug, content_type: props.eventCat, method});
    if (props.onResolve) {
      props.onResolve();
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
      handleShareSuccess('link_custom');
    } catch (error) {
      captureSentryException(error);
      dispatch(
        updateSnackbarInfo({
          message: 'Kopieren ist fehlgeschlagen. Der Link konnte nicht automatisch kopiert werden.',
        })
      );
    }
  };

  return (
    <Dialog closeable={true} handleClose={onClose} open={open} title='Teilen'>
      <Wrapper>
        <FacebookShareButton quote={text} url={url} onShareWindowClose={() => handleShareSuccess('facebook_custom')}>
          <ButtonContent>
            <FacebookIcon size={32} round={true} />
            <Label>Facebook</Label>
          </ButtonContent>
        </FacebookShareButton>
        <WhatsappShareButton title={text} url={url} onShareWindowClose={() => handleShareSuccess('whatsapp_custom')}>
          <ButtonContent>
            <WhatsappIcon size={32} round={true} />
            <Label>WhatsApp</Label>
          </ButtonContent>
        </WhatsappShareButton>
        <TelegramShareButton title={text} url={url} onShareWindowClose={() => handleShareSuccess('telegram_custom')}>
          <ButtonContent>
            <TelegramIcon size={32} round={true} />
            <Label>Telegram</Label>
          </ButtonContent>
        </TelegramShareButton>
        {windowIsDefined && navigator?.clipboard?.writeText ? (
          <ButtonContent onClick={handleCopy}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              leaveDelay={200}
              onClose={() => setTooltipOpen(false)}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title='Link erfolgreich in Zwischenablage kopiert.'
              placement='right'>
              <IconContainer>
                <ContentCopy sx={{fontSize: 20, color: COLORS.white}} />
              </IconContainer>
            </Tooltip>
            <Label>Link kopieren</Label>
          </ButtonContent>
        ) : (
          <TextField
            value={url}
            variant='outlined'
            InputProps={{
              color: 'secondary',
              sx: {
                width: '100%',
              },
            }}
          />
        )}
      </Wrapper>
    </Dialog>
  );
};

export {SocialShareButtons};
