const PhotosPageReducer = (state, action) => {
  switch (action.type) {
    case 'PHOTOS_FETCHING':
      return {...state, isFetching: true};
    case 'PHOTOS_UPDATE':
      return {...state, data: action.data, isFetching: false};
    default:
      return state;
  }
};

export {PhotosPageReducer};
