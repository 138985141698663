import React, {useEffect, useReducer, useState} from 'react';
import {ContentAdDesktop, FlexContainer} from 'app/styles/content';
import {useDispatch, useSelector} from 'react-redux';
import {Subheading} from 'app/styles/heading';
import styled from 'styled-components';
import {SIZES, Button, LoadingIndicator, LOADING_MESSAGE, COLORS} from '@fupa/fupa-uikit';
import {formatDayMonthDate, formatShortDate} from 'app/helpers/dateHelpers';
import MarketplaceContactForm from 'app/components/marketplace/marketplaceContactForm/MarketplaceContactForm';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {fetchDistrictMarketplaceDetail} from 'app/routes/district/DistrictMarketplaceDetailPageActions';
import {DistrictMarketplaceDetailPageReducer} from 'app/routes/district/DistrictMarketplaceDetailPageReducer';
import {TextWithLabel, TextWithLabelContainer} from 'app/components/textWithLabel/TextWithLabel';
import {useParams} from 'react-router';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {getPermission} from 'app/services/api';
import {selectAuthState, selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';
import {Send} from '@mui/icons-material';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {updateSnackbarInfo} from 'app/components/snackbar/SnackbarActions';
import {AdSlot} from 'app/components/ads/AdSlot';
import SignUpTeaserDialog from 'app/components/auth/SignUpTeaserDialog';
import FuPaApiError from 'app/models/error/FuPaApiError';

const SCTextWithLabel = styled(TextWithLabel)`
  &&& {
    font-size: ${SIZES['14']};
  }
`;
const CustomSubheading = styled(Subheading)`
  &&& {
    padding-top: ${SIZES['8']};
    padding-left: 0;
    margin-bottom: ${SIZES['24']};
  }
`;

const ButtonContainer = styled(FlexContainer)`
  justify-content: center;
  padding: ${SIZES['16']} 0;
`;

const DistrictMarketplaceDetailPage = () => {
  const {restoredData} = useRestoreData('DistrictMarketplaceDetailPage');
  const {detailId} = useParams();
  const initialState = restoredData ?? {
    isFetching: false,
    detail: {},
  };
  const [state, dispatch] = useReducer(DistrictMarketplaceDetailPageReducer, initialState);
  const dispatchRedux = useDispatch();

  const loadData = () => fetchDistrictMarketplaceDetail(detailId, dispatchRedux)(dispatch, state);
  const {detail, isFetching} = state;
  useFetchData(state, loadData, 'DistrictMarketplaceDetailPage');

  const [isAuthorised, setIsAuthorised] = useState(false);
  const isAuthenticated = useSelector(selectAuthState);
  const {user} = useSelector(selectCurrentUser);

  const [open, setOpen] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);

  const title = detail ? `Vereinsbörse - ${detail.title}${TITLE_POSTFIX}` : `Vereinsbörse${TITLE_POSTFIX}`;
  const metaDescription = detail ? detail.description : '';
  const meta = [
    {name: 'description', content: metaDescription},
    {
      name: 'robots',
      content: 'noindex, follow',
    },
  ];

  useEffect(() => {
    if (detail?.id && !isAuthorised) {
      getPermission('marketplace_entry', detail.id)
        .then(response => setIsAuthorised(response.data?.edit))
        .catch(error => {
          if (error.status === 401) {
            setIsAuthorised(false);
          }
        });
    } else if (!isAuthenticated && isAuthorised) {
      setIsAuthorised(false);
    }
  }, [detail, isAuthenticated]);

  const formatDate = (from, to) =>
    from === to ? formatShortDate(from) : `${formatDayMonthDate(from)} - ${formatShortDate(to)}`;

  const checkVenue = venue => {
    if (venue === 'home') {
      return 'Zuhause';
    } else if (venue === 'away') {
      return 'Auswärts';
    } else if (venue === 'both') {
      return 'beides möglich';
    } else {
      return venue;
    }
  };

  const infos = {
    Verein: detail.club ? detail.club.name : null,
    Autor: detail.author ? `${detail.author.firstName} ${detail.author.lastName}` : null,
    Altersklasse: detail.matchOptions && detail.matchOptions.ageGroup ? detail.matchOptions.ageGroup.name : null,
    Zeitraum: detail.matchOptions ? formatDate(detail.matchOptions.from, detail.matchOptions.to) : null,
    Spielort: detail.matchOptions ? checkVenue(detail.matchOptions.venue) : null,
    Beschreibung: detail.description ? detail.description : null,
    Kontakt: detail.contact && detail.contact.name ? detail.contact.name : null,
  };

  const infoBlocks = Object.keys(infos).map(info =>
    infos[info] ? (
      <SCTextWithLabel key={info} label={info} data={infos[info]} asInnerHTML={info === 'Beschreibung'} />
    ) : null
  );

  const content = isFetching ? <LoadingIndicator message={LOADING_MESSAGE.loadingData} bgColor={false} /> : infoBlocks;

  const openDialog = () => {
    setBtnClicked(true);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (btnClicked && isAuthenticated) {
      setOpen(true);
      setBtnClicked(false);
    }
  }, [isAuthenticated]);

  const handleResolve = () => {
    dispatchRedux(updateSnackbarInfo({message: 'Deine Nachricht wurde erfolgreich gesendet.'}));
    closeDialog();
  };

  const handleReject = error => {
    const fupaApiError = FuPaApiError.fromAxiosError(error);

    let errorMessage = 'Es ist ein Fehler beim Senden der Nachricht aufgetreten. Bitte versuche es später erneut.';

    if (error.status === 401) {
      errorMessage = 'Authentifizierungsfehler: Bitte melde dich neu an und versuche es erneut.';
    } else if (error.status === 404) {
      errorMessage =
        'Deine Nachricht konnte nicht gesendet werden. Die Anzeige existiert nicht oder wurde bereits gelöscht.';
    }

    fupaApiError.logError();

    dispatchRedux(
      updateSnackbarInfo({
        message: errorMessage,
      })
    );
    closeDialog();
  };

  const editBtn =
    isAuthorised && detail?.id ? (
      <EditFabContainer
        url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=verein_boerse&aktion=edit&bose_id=${detail.id}`}
      />
    ) : null;

  const modalTitle = detail.title ? detail.title : detail.category ? detail.category.name : null;
  const authorName = detail.anonymous ? 'Anonym' : detail.author ? detail.author.name : 'Unbekannt';

  const dialog = isAuthenticated ? (
    <MarketplaceContactForm
      detailId={detailId}
      handleClose={closeDialog}
      onContactResolve={handleResolve}
      onContactReject={handleReject}
      open={open}
      user={user}
      requestData={{title: modalTitle, club: detail.club, authorName: authorName}}
    />
  ) : (
    <SignUpTeaserDialog
      open={open}
      handleClose={closeDialog}
      title='Erstelle ein Konto und werde Teil der FuPa-Community.'
      subtitle='Antworte direkt auf Börseneinträge oder schalte selbst Anzeigen.'
      utmString='signup_teaser_marketplace_contact'></SignUpTeaserDialog>
  );

  return (
    <ContentAdDesktop noTabs top='4.5rem' fabBtn={editBtn}>
      <Helmet title={title} meta={meta} />
      <TextWithLabelContainer>
        <CustomSubheading variant='subheading2'>{detail.title}</CustomSubheading>
        {content}
        <ButtonContainer>
          <Button
            primary
            icon={<Send style={{color: COLORS.white}} />}
            label='Nachricht schreiben'
            onClick={openDialog}
          />
        </ButtonContainer>
      </TextWithLabelContainer>
      {dialog}
      <AdSlot adUnit='Content_1' />
    </ContentAdDesktop>
  );
};

export default DistrictMarketplaceDetailPage;
