import React from 'react';
import {FlexContainer} from 'app/styles/content';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {AppBarDesktopWrapper} from 'app/components/appBar/AppBarDesktopStyle';
import MatchHeaderTeam from 'app/components/match/matchDetailHeader/MatchHeaderTeam';
import MatchHeaderScore from 'app/components/match/matchDetailHeader/MatchHeaderScore';
import MatchHeaderFirstMatch from 'app/components/match/matchDetailHeader/MatchHeaderFirstMatch';

const MatchResultWrapper = styled.div`
  background-color: ${COLORS.white};
  width: 100%;
  max-width: ${props => props.theme.mobileContentWidth}px;
  margin: auto;
  margin-top: ${SIZES['56']};
  min-height: 8.75rem;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  ${props => props.theme.desktopLayout`
    margin-top: ${SIZES['72']};
    padding-top: ${SIZES['16']};
    margin-left: 0;
    max-width: ${props => props.theme.desktopContentWidth}px;
  `}
`;

const MatchInfo = styled(FlexContainer)`
  max-width: ${SIZES['400']};
  padding: ${SIZES['24']} ${SIZES['16']} 0 ${SIZES['16']};
  width: inherit;
  margin: auto;
`;

const MatchDetailHeader = ({matchInfo}) => {
  return (
    <AppBarDesktopWrapper>
      <MatchResultWrapper>
        <MatchHeaderFirstMatch match={matchInfo.firstMatch} />
        <MatchInfo>
          <MatchHeaderTeam team={matchInfo.homeTeam} fallbackName={matchInfo.homeTeamName} />
          <MatchHeaderScore match={matchInfo} />
          <MatchHeaderTeam team={matchInfo.awayTeam} fallbackName={matchInfo.awayTeamName} />
        </MatchInfo>
      </MatchResultWrapper>
    </AppBarDesktopWrapper>
  );
};

export default MatchDetailHeader;
