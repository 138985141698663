const MatchBestPlayerVotingReducer = (state, action) => {
  switch (action.type) {
    case 'MATCH_BEST_PLAYER_VOTING_UPDATE':
      return {
        ...state,
        isFetching: false,
        items: action.data.voting,
      };
    case 'MATCH_BEST_PLAYER_VOTING_FETCHING':
      return {
        ...state,
        isFetching: true,
      };
    case 'MATCH_BEST_PLAYER_VOTING_UPDATE_VOTE':
      const playerSlug = action.data.playerSlug;
      const index = state.items.findIndex(element => element.player?.slug === playerSlug);
      return {
        ...state,
        items: state.items.map((item, i) => (index === i ? action.data.votedPlayer : item)),
      };
    default:
      return state;
  }
};

export {MatchBestPlayerVotingReducer};
