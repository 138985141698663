import React, {useEffect, useReducer, useState} from 'react';
import {FlexContainer, StyledTypography} from 'app/styles/content';
import styled from 'styled-components';
import {COLORS, SIZES, LOADING_MESSAGE, LoadingIndicator, Typography, Image, Line} from '@fupa/fupa-uikit';
import {bool, object} from 'prop-types';
import {Alert} from 'app/components/banner/Alert';
import {fetchBestPlayerVoting, toggleBestPlayerVote} from 'app/components/match/bestPlayer/BestPlayerVotingActions';
import {MatchBestPlayerVotingReducer} from 'app/components/match/bestPlayer/BestPlayerVotingReducer';
import {useMediaQuery, useTheme} from '@mui/material';
import {Dialog} from 'app/components/dialog/Dialog';
import {useParams} from 'react-router';
import {ShareButtonInContent} from 'app/components/socialShare/ShareButtonInContent';
import {catchDataError} from 'app/helpers/actionsHelpers';

const VotingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SIZES['24']};
  width: ${SIZES['28']};
  border: ${SIZES['1']} solid ${props => (props.active ? COLORS.fupaSecondary : COLORS.lighterGrey)};
  border-radius: ${SIZES['2']};
  background-color: ${props => (props.active ? COLORS.fupaSecondary : COLORS.white)};
  cursor: pointer;

  :focus {
    background-color: ${props => (props.active ? COLORS.fupaSecondaryFocused : COLORS.lighterGrey)};
  }
`;

const CustomTypography = styled(Typography)`
  &&& {
    color: ${props => (props.active ? COLORS.white : COLORS.darkerGrey)};
  }
`;

const Header = styled(FlexContainer)`
  height: ${SIZES['36']};
  width: 100%;
  align-items: center;
  padding: 0 ${SIZES['16']};
`;

const Wrapper = styled(FlexContainer)`
  flex-direction: column;
  cursor: pointer;
  ${props =>
    !props.isDeactivated &&
    `
  &:hover {
    background-color: ${COLORS.lightestGrey};
  }
  `}
`;

const DataContainer = styled(FlexContainer)`
  align-items: center;
  height: ${SIZES['42']};
  padding: 0 ${SIZES['16']};
`;

const Rank = styled.div`
  width: ${SIZES['36']};
`;

const PlayerWrapper = styled(FlexContainer)`
  flex: 1;
  overflow: hidden;
`;

const Player = styled(FlexContainer)`
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: ${SIZES['48']};
`;

const Voting = styled(FlexContainer)`
  width: ${props => (props.active ? SIZES['72'] : SIZES['48'])};
  justify-content: ${props => (props.active ? 'space-between' : 'flex-end')};
`;

const Votes = styled(FlexContainer)`
  width: ${SIZES['24']};
  justify-content: center;
  align-items: center;
`;

const ShareWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem 1rem;
`;

const BestPlayerVoting = ({active, handleClose, open}) => {
  const initialData = {
    isFetching: false,
    items: [],
  };
  // use param from url instead of component prop to avoid undefined errors
  const {matchSlug} = useParams();

  const [state, dispatch] = useReducer(MatchBestPlayerVotingReducer, initialData);

  useEffect(() => {
    if (open && !items.length) {
      fetchBestPlayerVoting(matchSlug)(dispatch, state);
    }
  }, [open]);

  const [bannerState, setBannerState] = useState({isVisible: false, bannerText: '', variant: 'info'});
  const {items, isFetching} = state;
  const title = 'Jetzt abstimmen für den besten Spieler des Spiels!';
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // TODO: Wait for cleanup in api to remove this line -> Removes objects without values returned from EP /voting
  const players = items.filter(item => item.player);

  const onClick = player => {
    if (!active) {
      return;
    }
    const playerName = player.player.firstName
      ? `${player.player.firstName} ${player.player.lastName}`
      : player.player.lastName;
    if (!player.alreadyVoted) {
      toggleBestPlayerVote(
        'add',
        matchSlug,
        player.player.slug
      )(dispatch)
        .then(() =>
          setBannerState({
            isVisible: true,
            bannerText: `Du hast erfolgreich für ${playerName} abgestimmt.`,
            variant: 'success',
          })
        )
        .catch(e => {
          const errorData = catchDataError(e);
          if (errorData.status === 403) {
            setBannerState({
              isVisible: true,
              bannerText: errorData.message,
              variant: 'error',
            });
          } else {
            setBannerState({
              isVisible: true,
              bannerText: 'Deine Stimme konnte nicht gezählt werden. Bitte versuche es später erneut.',
              variant: 'error',
            });
          }
        });
    } else {
      toggleBestPlayerVote(
        'delete',
        matchSlug,
        player.player.slug
      )(dispatch)
        .then(() =>
          setBannerState({
            isVisible: true,
            bannerText: `Du hast deine Stimme für ${playerName} zurückgenommen.`,
            variant: 'success',
          })
        )
        .catch(() =>
          setBannerState({
            isVisible: true,
            bannerText: 'Deine Stimme konnte nicht zurückgenommen werden. Bitte versuche es später erneut.',
            variant: 'error',
          })
        );
    }
  };

  const votedPlayers = players.map((player, index) => (
    <Wrapper isDeactivated={player.player.isDeactivated} key={player.player.slug} onClick={() => onClick(player)}>
      <DataContainer>
        <Rank>
          <Typography variant='body1'>{index + 1}.</Typography>
        </Rank>
        <PlayerWrapper>
          <ImageContainer>
            <Image
              entity='player'
              circle={true}
              src={player.player.image}
              ratio='1:1'
              width={32}
              height={32}
              eager='true'
            />
          </ImageContainer>
          <Player>
            <StyledTypography isDeactivated={player.player.isDeactivated} variant='body1' useOverflow={true}>
              {player.player.firstName} {player.player.lastName}
            </StyledTypography>
            <StyledTypography isDeactivated={player.player.isDeactivated} variant='caption1'>
              {player.team.name.full}
            </StyledTypography>
          </Player>
        </PlayerWrapper>
        <Voting active={active}>
          <Votes>
            <Typography variant='body1'>{player.count}</Typography>
          </Votes>
          {active ? (
            <VotingButton active={player.alreadyVoted}>
              <CustomTypography active={player.alreadyVoted} variant='caption3'>
                +1
              </CustomTypography>
            </VotingButton>
          ) : null}
        </Voting>
      </DataContainer>
      <Line />
    </Wrapper>
  ));

  const content = isFetching ? (
    <LoadingIndicator message={LOADING_MESSAGE.loadingPlayers} bgColor={false} />
  ) : (
    votedPlayers
  );

  return (
    <Dialog
      closeable={true}
      fullScreen={fullScreen}
      handleClose={handleClose}
      open={open}
      title='Bester Spieler'
      titleColor={COLORS.fupaPrimary}>
      <Alert
        autoHideDuration={3000}
        onClose={() => setBannerState({...bannerState, isVisible: false})}
        open={bannerState.isVisible}
        offsetTop={SIZES[52]}
        text={bannerState.bannerText}
        variant={bannerState.variant}
      />
      <ShareWrapper>
        <ShareButtonInContent
          text={title}
          eventSlug={matchSlug}
          eventCat='matchvoting'
          callbackOnClose={handleClose}
          label={'Abstimmung teilen'}
        />
      </ShareWrapper>
      <Header>
        <Rank>
          <Typography variant='caption1'>Pl.</Typography>
        </Rank>
        <PlayerWrapper>
          <Typography variant='caption1'>Spieler</Typography>
        </PlayerWrapper>
        <Voting active={active}>
          <Typography variant='caption1'>Stimmen</Typography>
        </Voting>
      </Header>
      {content}
    </Dialog>
  );
};

BestPlayerVoting.propTypes = {
  active: bool,
  match: object,
};

export default BestPlayerVoting;
