import React, {useEffect, useState} from 'react';
import {Fab, Tooltip, Grow} from '@mui/material';
import {Apps, PlayCircle} from '@mui/icons-material';
import styled from 'styled-components';
import {ShareFabButton} from 'app/components/socialShare/ShareFabButton';
import {MatchPushFab} from 'app/components/floatingAction/MatchPushFab';
import {EditFab} from 'app/components/floatingAction/EditFab';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {COLORS} from '@fupa/fupa-uikit';
import {openDeepLink, useSportVideoDeepLink} from 'app/components/match/SportVideoDeepLink';
import {LoadingButton} from '@mui/lab';

const Container = styled.div`
  z-index: 60;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  max-width: 14rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoFab = ({deepLink, isLoading}) => {
  const handleVideo = () => {
    openDeepLink(deepLink);
  };

  return (
    <Tooltip title='Filmen mit Sport.Video' placement='right'>
      <Fab
        sx={{
          color: COLORS.darkerGrey,
          backgroundColor: COLORS.white,
          margin: '0.5rem 1rem',
        }}
        as={LoadingButton}
        loading={isLoading}
        disabled={!deepLink}
        onClick={handleVideo}
        color={'default'}
        size='small'
        aria-label='video'>
        <PlayCircle />
      </Fab>
    </Tooltip>
  );
};

const MatchFab = ({text, currentEntity, ...props}) => {
  const [open, setOpen] = useState(false);
  const isAuthenticated = useSelector(selectAuthState);
  const isVideoAllowed = currentEntity?.competition?.videoAllowed;
  const {deepLink, generateDeepLink, isLoading} = useSportVideoDeepLink();
  const handleToggle = () => {
    setOpen(!open);
    props.onToggle(!open);
  };

  useEffect(() => {
    if (!deepLink && open) {
      generateDeepLink(currentEntity);
    }
  }, [open]);

  // Don't show NotificationButton after the match
  const NotificationButton = currentEntity.section !== 'POST' ? <MatchPushFab currentEntity={currentEntity} /> : '';

  const editBtn =
    isAuthenticated && currentEntity?.id ? (
      <EditFab secondary url={`${process.env.ADMIN_URL}/fupa/admin/spielbericht.php?spiel=${currentEntity.id}`} />
    ) : null;

  const videoBtn = isVideoAllowed ? <VideoFab deepLink={deepLink} isLoading={isLoading} /> : null;

  return (
    <Container {...props}>
      <Tooltip title='Aktionen' placement='right'>
        <Fab color='secondary' onClick={handleToggle} size='medium' aria-label='actions'>
          <Apps />
        </Fab>
      </Tooltip>
      <Grow in={open}>
        <Wrapper>
          {NotificationButton}
          {videoBtn}
          {editBtn}
          <ShareFabButton text={text} eventCat='match' eventSlug={currentEntity.slug} />
        </Wrapper>
      </Grow>
    </Container>
  );
};

export {MatchFab};
