import React from 'react';
import styled from 'styled-components';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import {getPlayerShortName} from 'app/helpers/helpers';
import {Card, Football, TwoCards, PenaltyMissed} from 'app/components/icons';
import {Timer} from '@mui/icons-material';
import {Collapse} from '@mui/material';
import MatchDetailEventSummary from 'app/components/match/matchDetailHeader/MatchDetailEventSummary';
import {
  AwayHighlight,
  HomeHighlight,
  Name,
  Event,
  ElementContainer,
} from 'app/components/match/matchDetailHeader/MatchDetailEventSummaryStyles';

const Free = styled.div`
  flex: 1 45%;
`;

const MatchHighlight = ({highlight, homeTeamSlug}) => {
  let event = `${highlight.homeGoal}:${highlight.awayGoal}`;
  let icon = <Football style={{color: COLORS.darkerGrey, fontSize: 12}} />;
  let name = highlight.primaryRole?.lastName ? getPlayerShortName(highlight.primaryRole) : 'Unbekannt';
  let time = highlight.additionalMinute ? `${highlight.minute}'+${highlight.additionalMinute}` : `${highlight.minute}'`;

  switch (highlight.subtype) {
    case 'penalty_foul':
    case 'penalty_hand':
      const isPenaltyFail = highlight.type === 'penaltyfail';
      const color = isPenaltyFail ? COLORS.neonRed : COLORS.green;
      const getEventText = {
        penalty_foul: 'FE',
        penalty_hand: 'HE',
      };
      event = isPenaltyFail ? getEventText[highlight.subtype] ?? '' : event;
      icon = isPenaltyFail ? <PenaltyMissed style={{fontSize: 12}} /> : <Football style={{color, fontSize: 12}} />;
      break;
    case 'card_yellow_red':
      event = 'G/R';
      icon = <TwoCards style={{fontSize: 12}} />;
      break;
    case 'card_red':
      event = 'Rot';
      icon = <Card style={{color: COLORS.red, fontSize: 12}} />;
      break;
    case 'goal_own_goal':
      name = 'Eigentor';
      break;
    case 'time_penalty_start':
      event = `${highlight.length}'`;
      icon = <Timer style={{color: COLORS.neonRed, fontSize: 12}} />;
      break;
  }

  const content = (
    <>
      <Name>
        <Typography variant='caption1'>{name}</Typography>
      </Name>
      <Event>
        <Typography variant='caption1'>{event}</Typography>
      </Event>
      <ElementContainer home>{icon}</ElementContainer>
      <ElementContainer as={Typography} variant='caption3'>
        {time}
      </ElementContainer>
      <Free />
    </>
  );

  return highlight.team.slug === homeTeamSlug ? (
    <HomeHighlight>{content}</HomeHighlight>
  ) : (
    <AwayHighlight>{content}</AwayHighlight>
  );
};

const MatchHighlights = ({homeTeamSlug, highlights}) => {
  const regularHighlights = highlights.filter(highlight => highlight.subtype !== 'card_yellow');

  if (!regularHighlights.length) return null;

  return (
    <MatchDetailEventSummary label={'Highlights'}>
      {regularHighlights.map(highlight =>
        highlight.team ? (
          <Collapse id={highlight.id}>
            <MatchHighlight key={highlight.id} highlight={highlight} homeTeamSlug={homeTeamSlug} />
          </Collapse>
        ) : null
      )}
    </MatchDetailEventSummary>
  );
};

export default MatchHighlights;
