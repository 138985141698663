import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {updateWebsocketData} from 'app/actions/websocketActions';
import {captureSentryException} from 'app/services/sentryLogging';

/**
 * Create a websocket and add basic functionality
 * @returns {Object} Returns an object including Boolean websocketOpen and Func subscribeToChannel
 */
export const useWebSockets = (dispatch, conditionalTrigger) => {
  const webSocketRef = useRef(null);
  const pingTimeout = useRef(null);
  const IDLE_TIMEOUT_DURATION = 10 * 60; // The duration in seconds after which the connection gets automatically closed when no messages are sent on the connection
  const isAuthenticated = useSelector(selectAuthState);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const retries = useRef(0);

  const scheduleKeepAlivePing = timeoutInSeconds => {
    // schedule a keep-alive ping to prevent the websocket-connection from automatically closing if no messages are sent
    const websocket = webSocketRef.current;
    if (websocket) {
      if (pingTimeout.current) {
        clearTimeout(pingTimeout.current);
      }
      pingTimeout.current = setTimeout(() => {
        websocket.send(JSON.stringify({action: 'ping'}));
      }, 1000 * timeoutInSeconds);
    } else if (pingTimeout.current) {
      clearTimeout(pingTimeout.current);
    }
  };

  const onOpen = () => {
    // schedule initial ping on websocket open
    scheduleKeepAlivePing(IDLE_TIMEOUT_DURATION / 2);
    setWebsocketOpen(true);
  };

  const onClose = () => {
    // clear ping timeout on websocket close
    if (pingTimeout.current) {
      clearTimeout(pingTimeout.current);
    }
    dispatch(updateWebsocketData({}));
    setWebsocketOpen(false);
  };

  const onError = error => {
    // Close websocket on error
    const websocket = webSocketRef.current;
    if (websocket) {
      websocket.close();
      webSocketRef.current = null;
      setWebsocketOpen(false);
      if (retries.current > 0) {
        setTimeout(() => {
          connect();
          retries.current -= 1;
        }, 500);
      }
    }
    if (retries.current <= 0) {
      captureSentryException(error, {extra: {errorLocation: 'websocket on error handler', errorData: error}});
    }
  };

  const onMessage = e => {
    // check if message is 'pong', to schedule next keep-alive ping
    const data = JSON.parse(e.data);
    if (data.event === 'pong') {
      // schedule new ping after successful pong
      scheduleKeepAlivePing(IDLE_TIMEOUT_DURATION / 2);
    }
    dispatch(updateWebsocketData(data));
  };

  const subscribeToChannel = channel => {
    const websocket = webSocketRef.current;
    websocket?.send(JSON.stringify({action: 'subscribe', channel}));
  };

  const connect = () => {
    const websocket = (webSocketRef.current = new WebSocket(process.env.WEBSOCKET_URL));
    if (websocket) {
      websocket.onopen = onOpen;
      websocket.onclose = onClose;
      websocket.onerror = onError;
      websocket.onmessage = onMessage;
    }
    return websocket;
  };

  useEffect(() => {
    let websocket = null;
    if (!webSocketRef.current && conditionalTrigger && isAuthenticated && 'WebSocket' in window) {
      websocket = connect();
      retries.current = 3;
    }

    return () => {
      if (websocket) {
        websocket.close();
        webSocketRef.current = null;
      }
    };
  }, [isAuthenticated, conditionalTrigger]);

  return {websocketOpen, subscribeToChannel};
};
