import React, {useState} from 'react';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import {FlexContainer} from 'app/styles/content';
import {Button, COLORS, SIZES, Image, Line, Typography} from '@fupa/fupa-uikit';
import BestPlayerVoting from 'app/components/match/bestPlayer/BestPlayerVoting';
import {ProfileRowDataContainer, ProfileRowWrapper, ProfileTypography} from 'app/components/profile/ProfileStyles';
import {Person} from '@mui/icons-material';
import {useLocation} from 'react-router';
import {LoadingSkeleton} from 'app/components/avatar/Avatar';
import {useMarkOpenDialog} from 'app/hooks/useMarkOpenDialog';
import queryString from 'query-string';
import {DialogName} from 'app/common/DialogName';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DataContainer = styled(FlexContainer)`
  display: flex;
  align-items: center;
  padding: ${SIZES['8']} ${SIZES['16']} 0 ${SIZES['16']};
`;

const ButtonContainer = styled(FlexContainer)`
  justify-content: flex-end;
  margin-right: 1rem;
  margin-top: -0.5rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${SIZES['1']} solid
    ${props => (props.home ? COLORS.fupaPrimary : props.home === false ? COLORS.fupaSecondary : COLORS.darkGrey)};
  border-radius: ${SIZES['34']};
  min-width: ${SIZES['68']};
  height: ${SIZES['68']};
`;

const BorderLine = styled(Line)`
  margin-top: ${SIZES['8']};
`;

const PromoText = styled(Typography)`
  padding: 0 1rem;
  line-height: 1rem;
  &&& {
    opacity: ${props => (props.isLeague ? 1 : 0)};
  }
`;

const BestPlayer = ({match, homeTeam, awayTeam, lineupVoting, isFetching}) => {
  const homeStarters = homeTeam.filter(player => player.starting);
  const awayStarters = awayTeam.filter(player => player.starting);
  const {search} = useLocation();
  const [open, setOpen] = useState(() => {
    const searchParams = queryString.parse(search);
    return !!searchParams.dialog?.includes(DialogName.BestPlayerVoting);
  });

  useMarkOpenDialog(open, DialogName.BestPlayerVoting);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const voteButton = (
    <ButtonContainer>
      <Button label='Zur Abstimmung' onClick={openDialog} />
    </ButtonContainer>
  );
  const listButton = (
    <ButtonContainer>
      <Button label='Alle Stimmen' onClick={openDialog} />
    </ButtonContainer>
  );
  let image = <Person style={{color: COLORS.lightGrey, fontSize: 64}} />;
  let button, home, playerName, isDeactivated;
  let text;

  if (isFetching) {
    image = <LoadingSkeleton />;
  } else if (match.section === 'LIVE' && match.live && match.live.minute < 0.6 * match.live.regular) {
    text = 'Die Abstimmung für den besten Spieler ist noch nicht freigegeben.';
  } else if (!isFetching && !homeStarters.length && !awayStarters.length) {
    text = 'Es wurde noch kein Spieler in die Aufstellung eingetragen.';
  } else if (lineupVoting?.bestPlayer) {
    const player = lineupVoting.bestPlayer.player;
    const team = lineupVoting.bestPlayer.team;
    if (player) {
      isDeactivated = player.isDeactivated;
      playerName = `${player.firstName} ${player.lastName}`;
      image = player.image ? (
        <Image entity='player' circle={true} src={player.image} ratio='1:1' width={64} height={64} />
      ) : (
        image
      );
    }
    if (team) {
      home = match.homeTeam?.slug === team.slug;
    }
    text =
      lineupVoting.bestPlayer.count === 1
        ? `${lineupVoting.bestPlayer.count} Stimme`
        : `${lineupVoting.bestPlayer.count} Stimmen`;
    button = lineupVoting.isActive ? voteButton : listButton;
  } else {
    button = lineupVoting?.isActive ? voteButton : listButton;
    text = 'Es wurde noch keine Stimme abgegeben.';
  }

  const dataContent = (
    <DataContainer onClick={openDialog}>
      <ImageContainer home={home}>{image}</ImageContainer>
      <ProfileRowDataContainer>
        <ProfileTypography isDeactivated={isDeactivated} variant='subheading'>
          <span>{playerName}</span>
        </ProfileTypography>
        <ProfileTypography isDeactivated={isDeactivated} variant='body2'>
          {text}
        </ProfileTypography>
      </ProfileRowDataContainer>
    </DataContainer>
  );

  const isLeague = match?.competitionCategoryId === 1;
  return (
    <ProfileRowWrapper>
      <Header>
        <Subheading variant='subheading2'>Bester Spieler</Subheading>
      </Header>
      <PromoText isLeague={isLeague} variant='caption1'>
        Stimme für die besten Spieler, um ihnen in die Elf der Woche zu verhelfen!
      </PromoText>
      <div>
        {dataContent}
        {button}
        <BestPlayerVoting active={lineupVoting?.isActive} handleClose={closeDialog} open={open} />
      </div>
      <BorderLine />
    </ProfileRowWrapper>
  );
};

export default BestPlayer;
