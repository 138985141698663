import {useLocalStorage} from 'app/hooks/useLocalStorage';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import {useCallback, useRef} from 'react';
import WebStorageManager from 'app/services/web-storage/WebStorageManager';

export const ENABLE_TABOOLA_FEED_KEY = 'enableTaboolaFeed';

export default function useEnableTaboolaFeed(): [boolean, (value: boolean) => void] {
  const localStorageManager = useRef<WebStorageManager>(LocalStorageManager.getInstance());
  const enableTaboolaFeed = useLocalStorage(ENABLE_TABOOLA_FEED_KEY, true);

  const setEnableTaboolaFeed = useCallback(
    (value: boolean) => {
      localStorageManager.current.setItem(ENABLE_TABOOLA_FEED_KEY, value);
    },
    [localStorageManager.current]
  );

  return [enableTaboolaFeed, setEnableTaboolaFeed];
}
