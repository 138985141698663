import {SponsorImageInterface} from 'app/components/partner/SponsorImageInterface';
import {Region} from 'app/types/Region';

export const sponsoringPartnerByRegion = (regionSlug: string): SponsorImageInterface[] | null => {
  if (regionSlug === Region.Zuerich) {
    return [
      {
        imgPath: '/sponsoring/zuerich_trb.png',
        sponsor: 'TRB Maurer AG',
        link: 'https://trb-maurer.ch/',
      },
      {
        imgPath: '/sponsoring/zuerich_ruha.png',
        sponsor: 'Ruha Baer Immobilien AG',
        link: 'https://ruhabaer.ch/',
        justify: 'right',
      },
    ];
  }

  return null;
};
