import React, {useReducer} from 'react';
import MatchLineUps from 'app/components/match/matchData/MatchLineUps';
import MatchDetailTracktics from 'app/components/match/matchData/MatchDetailTracktics';
import {ContentWithStickyBanner} from 'app/styles/content';
import BestPlayer from 'app/components/match/bestPlayer/BestPlayer';
import {LoadingIndicator, LOADING_MESSAGE, ToggleButton, ToggleButtons, Placeholder} from '@fupa/fupa-uikit';
import {Subheading} from 'app/styles/heading';
import {fetchMatchLineup, updateSelectedTeam} from 'app/routes/match/MatchPageActions';
import {AdSlot} from 'app/components/ads/AdSlot';
import {useFetchData, useRestoreData, useDataFetchFinished} from 'app/hooks/dataHooks';
import {MatchLineUpPageReducer} from 'app/routes/match/MatchPageReducer';
import {useParams} from 'react-router';

const MatchLineUpPage = ({matchInfo}) => {
  const {restoredData} = useRestoreData('MatchLineUpPage');
  const {matchSlug} = useParams();
  const initialData = {
    isFetching: false,
    lineup: {},
    error: null,
    selectedTeam: 'home',
  };

  const initialState = restoredData ?? initialData;

  const [state, dispatch] = useReducer(MatchLineUpPageReducer, initialState);
  const loadData = () => {
    return fetchMatchLineup(matchSlug)(dispatch, state);
  };

  const {lineup, isFetching, error, selectedTeam} = state;
  useFetchData(state, loadData, 'MatchLineUpPage');

  const loadingDataFinished = useDataFetchFinished(isFetching);

  const setTeam = value => {
    if (value !== selectedTeam) {
      dispatch(updateSelectedTeam(value));
    }
  };

  const homeTeam = lineup && lineup.homeTeam ? lineup.homeTeam.lineup : [];
  const awayTeam = lineup && lineup.awayTeam ? lineup.awayTeam.lineup : [];
  const homeTeamAvgAge = lineup && lineup.homeTeam ? lineup.homeTeam.averageAge : 0;
  const awayTeamAvgAge = lineup && lineup.awayTeam ? lineup.awayTeam.averageAge : 0;

  let team, averageAge, displayTrackticsData, displayLineUps, displayBestPlayer;
  if (selectedTeam === 'home') {
    team = homeTeam;
    averageAge = homeTeamAvgAge;
  } else {
    team = awayTeam;
    averageAge = awayTeamAvgAge;
  }
  const showLoading = isFetching ? <LoadingIndicator message={LOADING_MESSAGE.loadingLineup} bgColor /> : null;

  if ((loadingDataFinished || homeTeam.length || awayTeam.length) && !error) {
    displayTrackticsData = <MatchDetailTracktics team={team} selectedTeam={selectedTeam} />;
    displayLineUps = <MatchLineUps team={team} averageAge={averageAge} matchId={matchInfo.id} />;
  } else if (error) {
    displayLineUps = <Placeholder>Beim Laden der Aufstellungen ist ein Fehler aufgetreten.</Placeholder>;
  }

  if (Object.keys(matchInfo).length && matchInfo.section !== 'PRE') {
    displayBestPlayer = (
      <BestPlayer
        isFetching={isFetching}
        match={{
          section: matchInfo.section,
          live: matchInfo.live,
          homeTeam: {slug: matchInfo.homeTeam?.slug},
          competitionCategoryId: matchInfo?.competition.category.id,
        }}
        lineupVoting={lineup.voting}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
    );
  }

  return (
    <ContentWithStickyBanner>
      {displayBestPlayer}
      <AdSlot key='MatchEvent_1' adUnit='MatchEvent_1' />
      <Subheading variant='subheading2'>Aufstellung</Subheading>
      <ToggleButtons activeValue={selectedTeam} onChange={value => setTeam(value)}>
        <ToggleButton as='div' value='home'>
          Heim
        </ToggleButton>
        <ToggleButton as='div' value='away'>
          Gast
        </ToggleButton>
      </ToggleButtons>
      {showLoading}
      {displayLineUps}
      {displayTrackticsData}
    </ContentWithStickyBanner>
  );
};

export default MatchLineUpPage;
