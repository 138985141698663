import {ssrRequestContext} from 'app/ssrContext';

const MAX_AGE_DEFAULT_BROWSER = 30;

/**
 * Defines the cache duration of the current request's server-side rendered content for CDN and browser.
 * See {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control} for more information.
 * @param cdnMaxAge {number} - The maximum cache duration in seconds for the CDN, must be greater than the max browser
 * cache duration.
 * @param browserMaxAge {number|undefined} optional, defaults to 30s
 */
export const setCacheMaxAge = (cdnMaxAge, browserMaxAge = MAX_AGE_DEFAULT_BROWSER) => {
  if (!cdnMaxAge || cdnMaxAge <= 0) {
    throw new Error('Invalid CDN cache duration specified - must be greater than 0 seconds.');
  }
  if (cdnMaxAge < browserMaxAge) {
    // Cloudfront always uses the greater value of s-maxage or max-age 🙄
    throw new Error(
      'CDN cache duration must always be greater than or equal to browser cache duration. Default value is 30 seconds.'
    );
  }
  ssrRequestContext.cacheControl = `public, s-maxage=${cdnMaxAge}, max-age=${browserMaxAge}`;
};
