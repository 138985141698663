import React, {useState} from 'react';
import {Fab, Tooltip} from '@mui/material';
import {COLORS} from '@fupa/fupa-uikit';
import {MatchPushButton} from 'app/components/match/MatchPushButton';

const MatchPushFab = ({currentEntity}) => {
  const [parentClicked, setParentClicked] = useState(false);
  const handleParentClick = () => {
    setParentClicked(!parentClicked);
  };

  return (
    <Tooltip title='Benachrichtigungen' placement='right'>
      <Fab
        sx={{color: COLORS.darkerGrey, backgroundColor: COLORS.white, margin: '0.5rem 1rem'}}
        onClick={handleParentClick}
        size='small'
        aria-label='notifications'>
        <MatchPushButton parentClicked={parentClicked} currentEntityId={currentEntity.id} />
      </Fab>
    </Tooltip>
  );
};

export {MatchPushFab};
