import React, {useState} from 'react';
import {openMobileShareDialog} from 'app/helpers/openShareDialog';
import {useNative} from 'app/contexts/NativeContext';

const useShare = (eventCat, eventSlug, text, callbackOnShare, callbackOnClose) => {
  const [open, setOpen] = useState(false);
  const nativeInfo = useNative();

  const handleShare = () => {
    callbackOnShare?.();
    const newOpenState = openMobileShareDialog(text, eventCat, eventSlug, nativeInfo);
    if (newOpenState !== open) {
      setOpen(newOpenState);
    }
  };

  const handleShareClose = () => {
    callbackOnClose?.();
    setOpen(false);
  };

  return {open, handleShare, handleShareClose};
};

export {useShare};
