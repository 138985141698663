import {api} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getGallery = gallerySlug => {
  return api.get(`/galleries/${gallerySlug}`);
};

function fetchingPhotos() {
  return {
    type: 'PHOTOS_FETCHING',
  };
}

function updatePhotos(data) {
  return {
    type: 'PHOTOS_UPDATE',
    data,
  };
}

function setPhotosHeader(dispatch, data) {
  let parent;
  if (data.match) {
    const getLevel = level => (level > 1 ? ` ${convertToRoman(level)}` : '');
    const homeTeamName = data.match.homeTeam?.name?.middle
      ? data.match.homeTeam.name.middle + getLevel(data.match.homeTeam.level)
      : 'unbekanntes Team';
    const awayTeamName = data.match.awayTeam?.name?.middle
      ? data.match.awayTeam.name.middle + getLevel(data.match.awayTeam.level)
      : 'unbekanntes Team';

    parent = {
      name: `${homeTeamName} - ${awayTeamName}`,
      entity: 'match',
      slugs: {
        matchSlug: data.match.slug,
      },
    };
  } else {
    parent = {
      name: data.title,
    };
  }
  dispatch(
    changeHeaderText({
      title: 'Galerie',
      parent,
    })
  );
}

function fetchPhotosSSR(slug) {
  return async function (dispatch, getState) {
    try {
      let response = await getGallery(slug);
      const data = response.data;
      checkApiRedirect(data.slug, slug, null, null, getState);
      dispatch(updateHistory('undefined', {store: 'PhotosPage', data: {data, isFetching: false, error: null}}));
      setPhotosHeader(dispatch, data);
      if (data.user) {
        // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
        return trySettingDefaultDistrict(data.user, dispatch, getState);
      }
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchPhotos(slug, dispatchRedux, getState) {
  return function (dispatch, state) {
    if (Object.keys(state.data).length && state.data.slug === slug) {
      setPhotosHeader(dispatchRedux, state.data);
      return;
    }
    if (state.error) {
      return;
    }
    dispatch(fetchingPhotos());
    return getGallery(slug)
      .then(response => {
        const data = response.data;
        checkApiRedirect(data.slug, slug);
        dispatch(updatePhotos(data));
        setPhotosHeader(dispatchRedux, data);
        if (data.user) {
          return trySettingDefaultDistrict(data.user, dispatchRedux, getState);
        }
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

export {fetchPhotosSSR, fetchPhotos};
