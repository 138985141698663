import React, {useEffect, useState} from 'react';
import {TransitionGroup} from 'react-transition-group';
import {Collapse} from '@mui/material';
import {Typography} from '@fupa/fupa-uikit';
import ProgressBar from 'app/components/match/ProgressBar';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import {
  HomeGoal,
  AwayGoal,
  MatchScoreContainer,
  MatchScore,
} from 'app/components/match/matchDetailHeader/MatchDetailHeaderStyles';

const MinuteContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
`;

const DisplayMinute = styled(Typography)`
  &:before {
    content: ${props => `"` + props.content + `"`};
    font-size: 100%;
    animation: ${props => (props.goalChange ? 'goalText 3s' : 'none')};
  }

  @keyframes goalText {
    0%,
    100% {
      font-size: 50%;
      opacity: 0;
      content: 'Toooor!';
    }
    40%,
    60% {
      font-size: 100%;
      opacity: 1;
      content: 'Toooor!';
    }
  }
`;

const MatchLiveMinute = ({live, homeGoal, awayGoal}) => {
  const {displayMinute, displayAdditionalMinute, displayUnit} = useLiveMinute(
    live.minute,
    live.running,
    live.period,
    live.additionalMinute,
    live.periodPlaytime
  );
  const progressMinute = typeof displayMinute === 'number' ? displayMinute : live.minute;
  const progress = progressMinute < live.regular ? (progressMinute / live.regular) * 100 : 100;
  const [goalChange, setGoalChange] = useState(null);
  const [init, setInit] = useState(true);

  const displayAddMinute = displayAdditionalMinute > 0 ? ` +${displayAdditionalMinute}` : '';
  useEffect(() => {
    if (!init) {
      setGoalChange(`${homeGoal}_${awayGoal}`);
    }
    setInit(false);
  }, [homeGoal, awayGoal]);

  return (
    <MatchScoreContainer>
      <MatchScore>
        <TransitionGroup>
          <Collapse timeout={1000} key={`updateHomeGoal_${homeGoal}`}>
            <HomeGoal>
              <Typography variant='display2'>{homeGoal}</Typography>
            </HomeGoal>
          </Collapse>
        </TransitionGroup>
        <Typography variant='display2'>:</Typography>
        <TransitionGroup>
          <Collapse timeout={1000} key={`updateAwayGoal_${awayGoal}`}>
            <AwayGoal>
              <Typography variant='display2'>{awayGoal}</Typography>
            </AwayGoal>
          </Collapse>
        </TransitionGroup>
      </MatchScore>
      <MinuteContainer>
        <ProgressBar progress={progress} />
        <DisplayMinute
          data-testid='live-minute'
          key={`updateGoalMinute_${goalChange}`}
          content={`${displayMinute}${displayUnit}${displayAddMinute}`}
          goalChange={goalChange}
          variant='caption3-active'
        />
      </MinuteContainer>
    </MatchScoreContainer>
  );
};

export default MatchLiveMinute;

const useLiveMinute = (lastMatchEventMinute, isMatchRunning, matchPeriod, additionalMinute, periodPlaytime) => {
  const initDisplayMinute = () => {
    let displayMinute;
    let displayUnit = '';
    const displayAdditionalMinute = additionalMinute;
    if (isMatchRunning) {
      displayMinute = lastMatchEventMinute;
      displayUnit = `'`;
      if (matchPeriod === 'shootout') {
        displayMinute = 'Elfmeterschießen';
        displayUnit = '';
      }
    } else {
      if (matchPeriod === 'halftime') {
        displayMinute = 'Halbzeit';
      } else if (matchPeriod === 'overtimeHalftime') {
        displayMinute = 'Halbzeit Verlängerung';
      } else {
        displayMinute = 'Unterbrechung';
      }
    }
    return {displayMinute, displayAdditionalMinute, displayUnit};
  };

  const [{displayMinute, displayAdditionalMinute, displayUnit}, setDisplayMinute] = useState(initDisplayMinute);

  let interval;
  useEffect(() => {
    if (isMatchRunning) {
      clearInterval(interval);
      setDisplayMinute(() => ({
        displayUnit: `'`,
        displayMinute: lastMatchEventMinute,
        displayAdditionalMinute: additionalMinute,
      }));
      interval = setInterval(() => {
        setDisplayMinute(prev => ({
          ...prev,
          displayMinute: prev.displayMinute < periodPlaytime ? prev.displayMinute + 1 : prev.displayMinute,
          displayAdditionalMinute:
            periodPlaytime === prev.displayMinute ? prev.displayAdditionalMinute + 1 : prev.displayAdditionalMinute,
        }));
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setDisplayMinute(initDisplayMinute);
    }
  }, [lastMatchEventMinute, matchPeriod, additionalMinute]);

  return {displayMinute, displayAdditionalMinute, displayUnit};
};
