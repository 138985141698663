// ValidationRules always consist of a function and an error-message in the form `function || 'error-message'`.
// If the function returns false, then the error-message is returned.

const isFilled = value => value.length > 0 || 'Bitte fülle dieses Feld aus.';

const isAtInAddress = value => /@/.test(value) || `Bitte füge ein "@" in die E-Mail-Adresse ein.`;

const isValidEmailAddress = value =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) || 'Bitte gib eine valide E-Mail-Adresse ein.';

const isNumber = value => /^[0-9]*$/.test(value) || 'Bitte gib nur Zahlen ein.';

export {isFilled, isAtInAddress, isValidEmailAddress, isNumber};
