import React from 'react';
import {Subheading} from 'app/styles/heading';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import TrackticsPlayer from 'app/components/match/matchData/TrackticsPlayer';

const Container = styled.div`
  padding-bottom: 1.5rem;
`;

const Header = styled(FlexContainer)`
  justify-content: space-between;
`;

const TrackticsHeader = styled.div`
  margin-top: ${SIZES['24']};
  margin-bottom: ${SIZES['16']};
  padding-right: ${SIZES['16']};
`;

const TableHeader = styled(FlexContainer)`
  padding: 0 ${SIZES['16']};
  justify-content: flex-start;
  line-height: ${SIZES['18']};
  align-items: center;
`;

const NameColumn = styled.div`
  flex: 1 55%;
`;

const StatColumns = styled(FlexContainer)`
  flex: 1 15%;
  justify-content: center;
`;

const MatchDetailTracktics = ({team, selectedTeam}) => {
  const teamData = team.map(player =>
    !player.coach ? (
      player.statistics.details ? (
        <TrackticsPlayer
          key={player.player.slug}
          data={player.statistics.details}
          playerName={player.player.firstName + ' ' + player.player.lastName}
          clubColor={selectedTeam}
          playerSlug={player.player.slug}
        />
      ) : null
    ) : null
  );
  const getTrackticsData = () => {
    if (teamData.some(data => data)) {
      return (
        <div>
          <TableHeader>
            <NameColumn>
              <Typography variant='caption1'>Spieler</Typography>
            </NameColumn>
            <StatColumns>
              <Typography variant='caption1'>Sprints</Typography>
            </StatColumns>
            <StatColumns>
              <Typography variant='caption1'>km/h</Typography>
            </StatColumns>
            <StatColumns>
              <Typography variant='caption1'>km</Typography>
            </StatColumns>
          </TableHeader>
          {teamData}
        </div>
      );
    } else {
      return null;
    }
  };

  const trackticsData = getTrackticsData();
  if (!trackticsData) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Subheading variant='subheading2'>Laufdaten</Subheading>
        <TrackticsHeader>
          <img src='/tracktics-logo.svg' height={24} width={114} alt='Tracktics' />
        </TrackticsHeader>
      </Header>
      {trackticsData}
    </Container>
  );
};

export default MatchDetailTracktics;
