import React from 'react';
import {FlexContainer} from 'app/styles/content';
import styled from 'styled-components';
import {SIZES, Typography} from '@fupa/fupa-uikit';

const Container = styled(FlexContainer)`
  justify-content: space-between;
  padding-bottom: ${SIZES['24']};
`;

const StartingAvgAge = ({caption, age}) => {
  const checkCaption = caption ? caption : '';

  return (
    <Container>
      <Typography variant='caption1'>{checkCaption}</Typography>
      <Typography variant='caption1'>∅ {age}</Typography>
    </Container>
  );
};

export default StartingAvgAge;
