import {api} from 'app/services/api';

const getMatchVoting = matchSlug => {
  return api.get(`/matches/${matchSlug}/voting`);
};

const votePlayer = (voteType, matchSlug, playerSlug) => {
  if (voteType === 'add') {
    return api.post(`/matches/${matchSlug}/voting/${playerSlug}`);
  } else if (voteType === 'delete') {
    return api.delete(`/matches/${matchSlug}/voting/${playerSlug}`);
  }
};

function fetchingMatchVoting() {
  return {
    type: 'MATCH_BEST_PLAYER_VOTING_FETCHING',
  };
}

function updateMatchVoting(data) {
  return {
    type: 'MATCH_BEST_PLAYER_VOTING_UPDATE',
    data,
  };
}

function updateVotedPlayer(data) {
  return {
    type: 'MATCH_BEST_PLAYER_VOTING_UPDATE_VOTE',
    data,
  };
}

function fetchBestPlayerVoting(matchSlug) {
  return function(dispatch) {
    dispatch(fetchingMatchVoting());
    return getMatchVoting(matchSlug).then(response => {
      const voting = response.data;
      dispatch(updateMatchVoting({voting, matchSlug}));
    });
  };
}

function toggleBestPlayerVote(voteType, matchSlug, playerSlug) {
  return async function (dispatch) {
    let response = await votePlayer(voteType, matchSlug, playerSlug);
    const votedPlayer = response.data;
    dispatch(updateVotedPlayer({votedPlayer, playerSlug}));
  };
}

export {fetchBestPlayerVoting, toggleBestPlayerVote};
