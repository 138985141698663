import {SiteSponsoring} from 'app/components/partner/SiteSponsoring';
import {Branding} from 'app/components/partner/branding';
import React from 'react';
import styled, {css} from 'styled-components';

const Partner = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: ${props => props.theme.desktopContentWidth}px;
  max-height: 1.75rem;
  justify-content: space-between;
  padding: ${props => (props.noPadding ? '0' : '0 1rem')};
  @media only screen and (max-width: 359px) {
    padding: 0;
  }
  ${props =>
    !props.bgColor &&
    css`
      margin-top: 0.5rem;
    `}
`;

const PartnerTeaser = props => {
  return (
    <Partner {...props}>
      <SiteSponsoring />
      <Branding alwaysReserve={props.alwaysReserve} />
    </Partner>
  );
};

export default PartnerTeaser;
