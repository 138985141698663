import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import {useDispatch, useSelector, useStore} from 'react-redux';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import Author from 'app/components/author/Author';
import debounce from 'app/helpers/debounce';
import {numberWithThousandSeparator} from 'app/helpers/helpers';
import {formatShortDate} from 'app/helpers/dateHelpers';
import {Helmet} from 'react-helmet-async';
import {object} from 'prop-types';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {fetchPhotos} from 'app/routes/photos/PhotosPageActions';
import {PhotosPageReducer} from 'app/routes/photos/PhotosPageReducer';
import {LoadingIndicator, LOADING_MESSAGE, Image, Placeholder, Typography} from '@fupa/fupa-uikit';
import {useClickTracking} from 'app/hooks/useClickTracking';
import {useSiteSpecificAdTargeting} from 'app/components/ads/useAdTargeting';
import {FullScreenImageSlider} from 'app/components/backdrop/FullscreenImage';
import {useHistory, useLocation, useParams} from 'react-router';
import {getPermission} from 'app/services/api';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {useGalleryNRDTrigger} from 'app/hooks/nativeRatingDialogTrigger/useGalleryNRD';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {MatchRow} from 'app/components/match/matchRow/MatchRow';
import {useFullscreen, getFullScreenElement} from 'app/hooks/useFullscreen';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';
import {ShareButtonInContent} from 'app/components/socialShare/ShareButtonInContent';
import {EditButtonInContent} from 'app/components/floatingAction/EditButtonInContent';

const ActionBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`;

const Header = styled.div`
  display: flex;
  flex: 0 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  margin-bottom: 1rem;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

const MatchHeading = styled(Subheading)`
  &&& {
    padding-top: 0;
    margin-bottom: 0;
  }
`;

const CustomSubheading = styled(Typography)`
  margin-top: 0;
  padding-top: 0.5rem;
  margin-bottom: 0;
`;

const Match = styled.div`
  padding-bottom: 1rem;
  h2 {
    margin-top: 0;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 0.5rem;
  height: 56.25vw;
  width: 100%;
  overflow: hidden;
  ${props => props.theme.desktopLayout`
    max-width: ${props => props.theme.desktopContentWidth}px;
    max-height: ${props => (props.theme.desktopContentWidth * 9) / 16}px;
  `}
`;

const getMetaInformation = (gallery, pathname) => {
  const galleryExists = Object.keys(gallery).length > 0 && !(gallery.message && gallery.status === 404);
  const galleryName = galleryExists ? gallery.title : '';
  const galleryDate = galleryExists ? formatShortDate(gallery.created) : '';
  const photographer = galleryExists ? gallery?.userAlias || gallery?.user?.name || '' : '';
  const count = gallery?.items?.length ?? '';
  const title = `Galerie ${galleryName} vom ${galleryDate}${TITLE_POSTFIX}`;
  const description = `${count} Fotos vom Fotograf ${photographer} Datum ${galleryDate} - ${galleryName}`;
  const meta = [
    {name: 'description', content: description},
    {name: 'robots', content: 'noindex, follow'},
    {property: 'og:description', content: description},
    {property: 'og:image:type', content: 'image/jpeg'},
    {property: 'og:image:width', content: '1920px'},
    {property: 'og:image:height', content: '1080px'},
    {property: 'og:type', content: `article`},
    {property: 'og:title', content: title},
    {property: 'og:site_name', content: 'FuPa'},
    {property: 'og:url', content: `https://www.fupa.net${pathname}`},
    {property: 'fb:app_id', content: '939336572887264'},
  ];
  if (gallery.thumbnail?.image) {
    const ogImage = {property: 'og:image', content: `${gallery.thumbnail.image.path}1920x1080.jpeg`};
    const ogSecureImage = {property: 'og:image:secure_url', content: `${gallery.thumbnail.image.path}1920x1080.jpeg`};
    meta.push(ogImage, ogSecureImage);
  }
  return {title, meta};
};

const PhotosPage = () => {
  const {restoredData} = useRestoreData('PhotosPage');
  const {photosSlug} = useParams();
  const {hash, pathname} = useLocation();
  const {exitFullscreen, requestFullscreen} = useFullscreen();
  const history = useHistory();
  const initialState = restoredData ?? {
    isFetching: false,
    data: {},
  };
  const [state, dispatch] = useReducer(PhotosPageReducer, initialState);
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const loadData = () => fetchPhotos(photosSlug, dispatchRedux, getState)(dispatch, state);
  const {data, isFetching} = state;
  useFetchData(state, loadData, 'PhotosPage');

  const [scrollPositionToRestore, setScrollPositionToRestore] = useState(null);
  // use fullscreen state to handle fullscreen for ios correctly independant from useFullscreen hook
  const [fullscreen, setFullscreen] = useState(null);
  const [detailImageIndex, setDetailImageIndex] = useState(0);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const photosWrapper = useRef(null);
  const isAuthenticated = useSelector(selectAuthState);
  const {title, meta} = getMetaInformation(data, pathname);
  const contentLoaded = data?.items?.length && !isFetching;
  useGalleryNRDTrigger(contentLoaded, !!fullscreen);

  const updateHash = (event, index) => {
    if (index) {
      const hash = (index + 1).toString();
      history.replace({hash});
    } else {
      const AppBarHeight = 72;
      const photos = photosWrapper.current?.children ? [...photosWrapper.current.children] : [];
      const visible = photos.find(container => container.getBoundingClientRect().top >= AppBarHeight);
      if (visible && visible.id) {
        history.replace({hash: visible.id});
      }
    }
  };

  let viewportEvent;
  useEffect(() => {
    viewportEvent = debounce(updateHash, 250);
    return () => viewportEvent.cancel();
  });

  const club = useMemo(() => {
    return [data?.match?.homeTeam?.clubSlug, data?.match?.awayTeam?.clubSlug];
  }, [data]);

  useSiteSpecificAdTargeting({club}, [club]);

  useEffect(() => {
    addHashListener();
    return () => removeHashListener();
  });

  const lockScroll = () => {
    const scrollPosition = window.pageYOffset;
    setScrollPositionToRestore(scrollPosition);
  };
  const unlockScroll = () => {
    if (scrollPositionToRestore) {
      window.scrollTo(0, scrollPositionToRestore);
    }
  };

  useEffect(() => {
    if (fullscreen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [fullscreen]);

  // check permission for edit button
  useEffect(() => {
    if (data?.id && !isAuthorised) {
      getPermission('gallery', data.id)
        .then(response => setIsAuthorised(response.data?.edit))
        .catch(error => {
          if (error.status === 401) {
            setIsAuthorised(false);
          }
        });
    } else if (!isAuthenticated && isAuthorised) {
      setIsAuthorised(false);
    }
  }, [data, isAuthenticated]);

  const clickData = useMemo(() => ({id: data.id, hash}), [data, hash]);
  const {clicks} = useClickTracking('gallery', clickData);

  const addHashListener = () => {
    document.addEventListener('scroll', viewportEvent);
    window.addEventListener('resize', viewportEvent);
    window.addEventListener('orientationChange', viewportEvent);
  };

  const removeHashListener = () => {
    document.removeEventListener('scroll', viewportEvent);
    window.removeEventListener('resize', viewportEvent);
    window.removeEventListener('orientationChange', viewportEvent);
  };

  const handleClose = () => {
    const hasFullscreenElement = getFullScreenElement(document);
    if (hasFullscreenElement) {
      exitFullscreen();
    }
    setFullscreen(null);
  };

  const handleOpen = detailImageIndex => {
    const elem = document.getElementById('modal-root');
    requestFullscreen(elem);
    setFullscreen(true);
    setDetailImageIndex(detailImageIndex);
  };

  let match, photos, photosCount, photographer;
  if (Object.keys(data).length > 0 && !isFetching) {
    match = data.match ? (
      <Match>
        <MatchHeading variant='subheading2'>Spielbericht</MatchHeading>
        <MatchRow standalone={true} match={data.match} colored={false} />
      </Match>
    ) : null;

    if (data.items) {
      photos = data.items.map((item, index) => {
        return (
          <ImageContainer key={item.image.path} id={index + 1} onClick={() => handleOpen(index)}>
            <Image entity='gallery' actualWidthFactor={1} ratio='16:9' src={item.image} />
          </ImageContainer>
        );
      });
      photosCount = data.items.length;
    }

    if (data.user) {
      photographer = <Author image={data.user.image} job='Fotograf' name={data.user.name} />;
    }

    if (data.userAlias) {
      photographer = <Author image={null} job='Fotograf' name={data.userAlias} />;
    }
  }

  const showLoading = isFetching ? <LoadingIndicator message={LOADING_MESSAGE.loadingData} bgColor={false} /> : null;
  const showNoData =
    data.items && data.items.length === 0 && !isFetching ? (
      <Placeholder>Es sind keine Fotos vorhanden.</Placeholder>
    ) : null;

  const editBtn =
    isAuthorised && data?.id ? (
      <EditButtonInContent
        url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=galerie_edit&aktion=edit&gale_id=${data.id}`}
      />
    ) : (
      <div />
    );

  const shareBtn = (
    <ShareButtonInContent
      text={`Galerie: ${data.title}`}
      eventSlug={data.slug}
      eventCat='photos'
      label={'Fotos teilen'}
    />
  );

  const galleryStats = `${photosCount} Fotos | ${numberWithThousandSeparator(clicks)} Aufrufe`;

  return (
    <>
      <ContentAdDesktop noTabs top='4.5rem' contentPaddingBottom={'4rem'}>
        <Helmet title={title} meta={meta} />
        {match}
        {isFetching ? null : (
          <>
            <ActionBtnWrapper>
              {editBtn}
              {shareBtn}
            </ActionBtnWrapper>
            <Header>
              <SubContainer>
                <CustomSubheading variant='subheading2'>Fotos</CustomSubheading>
                <Typography variant='caption1'>{galleryStats}</Typography>
              </SubContainer>
              {photographer}
            </Header>
          </>
        )}
        <div ref={photosWrapper}>{photos}</div>
        {showLoading}
        {showNoData}
      </ContentAdDesktop>
      <StickyMobileBannerAdSlot />
      <FullScreenImageSlider
        entity='gallery'
        handleClose={handleClose}
        open={!!fullscreen}
        startIndex={detailImageIndex}
        images={data.items}
        callback={imageIndex => updateHash(null, imageIndex)}
      />
    </>
  );
};

PhotosPage.propTypes = {
  match: object.isRequired,
};

export default PhotosPage;
