import React from 'react';
import {Button} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {COLORS} from '@fupa/fupa-uikit';

const EditButtonInContent = ({url, label = 'Bearbeiten'}) => {
  const handleEdit = () => {
    window.open(url);
  };

  return (
    <>
      <Button
        sx={{fontFamily: 'PT Sans', fontWeight: 'bold', color: COLORS.darkerGrey}}
        color='neutral'
        onClick={handleEdit}
        startIcon={<Edit color='inherit' />}
        variant='contained'
        size='small'>
        {label}
      </Button>
    </>
  );
};

export {EditButtonInContent};
