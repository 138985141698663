import {AxiosError, AxiosResponse} from 'axios';
import {DefaultApiErrorResponse} from 'app/common/errorTypes';
import {TimeoutMessage} from 'app/components/error/TimeoutMessage';
import {FuPaApiErrorInterface} from 'app/models/error/FuPaApiErrorInterface';
import {captureSentryException} from 'app/services/sentryLogging';

export default class FuPaApiError implements FuPaApiErrorInterface {
  status: number;
  message: string;
  translatedMessage: string | undefined;

  constructor(data: FuPaApiErrorInterface) {
    this.status = data.status;
    this.message = data.message;
    this.translatedMessage = data.translatedMessage;
  }

  static fromAxiosError(error: AxiosError<DefaultApiErrorResponse, any>): FuPaApiError {
    if (error.response) {
      return new FuPaApiError(this.setFromAxiosResponse(error.response, error.message));
    } else {
      return new FuPaApiError({
        status: error.status ?? 0,
        message: error.message,
        translatedMessage: undefined,
      });
    }
  }

  public getHttpStatusId(): string {
    return `http-error-${this.status}`;
  }

  public isTimeout(): boolean {
    return this.status === 0 && TimeoutMessage.test(this.message);
  }

  public toPlain(): FuPaApiErrorInterface {
    return {
      status: this.status,
      message: this.message,
      translatedMessage: this.translatedMessage,
    };
  }

  public logClientError(messagePrefix?: string) {
    if (this.isClientError()) {
      const errorMessage = messagePrefix ? `${messagePrefix}: ${this.message}` : this.message;
      this.logError(errorMessage);
    }
  }

  public logError(message: string = this.message) {
    captureSentryException(new Error(message), {
      extra: {errorData: this.toPlain()},
    });
  }

  private static setFromAxiosResponse(
    response: AxiosResponse<DefaultApiErrorResponse, any>,
    message: string
  ): FuPaApiErrorInterface {
    if (response.data.error) {
      return {
        status: response.data.error.code,
        message: response.data.error.message,
        translatedMessage: response.data.error.translated_message,
      };
    }
    return {
      status: response.status,
      message,
      translatedMessage: undefined,
    };
  }

  private isClientError(): boolean {
    return this.status >= 400 && this.status < 500;
  }
}
