import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router';

function SignUpTeaserDialog({open, handleClose, title, subtitle, utmString}) {
  const {pathname} = useLocation();

  const onMessageReceivedFromIframe = event => {
    if (event.data === 'closeAuthDialog') {
      handleClose();
    }
  };

  const addIframeListener = () => window.addEventListener('message', onMessageReceivedFromIframe);
  const removeIframeListener = () => window.removeEventListener('message', onMessageReceivedFromIframe);

  useEffect(() => {
    if (open) {
      addIframeListener();
    }
    return () => removeIframeListener();
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton
          aria-label='close'
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <Close color={'action'} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <iframe
          style={{border: 'none'}}
          name={`headline=${title};subtitle=${subtitle}`}
          src={`/auth/signup/teaser?redirectUrl=${encodeURIComponent(
            pathname
          )}&blank=true&utm_source=fupa&utm_medium=dialog&utm_campaign=${utmString}`}
          width='100%'
          height='650px'
        />
      </DialogContent>
    </Dialog>
  );
}

export default SignUpTeaserDialog;
