import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {Image, SIZES} from '@fupa/fupa-uikit';
import {createSelector} from 'reselect';
import {useSelector} from 'react-redux';

const BrandImageContainer = styled.div`
  position: absolute;
  right: 0;
`;

// prettier-ignore
const BrandingContainer = styled.div`
  position: relative;
  height: 1.25rem;
  width: inherit;
  align-self: flex-end;
  justify-self: flex-end;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  margin-bottom: ${SIZES["8"]};

    // http://css3.bradshawenterprises.com/cfimg/#cfimg3
    // For "n" images You must define:
    // a=presentation time for one image -> 3s
    // b=duration for cross fading -> 1s
    // Total animation-duration is of course t=(a+b)*n
    //
    // animation-delay = t/n or = a+b
    //
    // Percentage for keyframes:
    // 1. 0%
    // 2. a/t*100%
    // 3. (a+b)/t*100% = 1/n*100%
    // 4. 100%-(b/t*100%)
    // 5. 100%

  ${props => props.countBrandings > 1 && css`
     @keyframes brandFadeInOut {
         0% {
           z-index: 20;
           opacity:1;
         }
         ${props => (3000 / (4000 * props.countBrandings)) * 100}% {
           z-index: 20;
           opacity:1;
         }
         ${props => (1 / props.countBrandings) * 100}% {
           z-index: 0;
           opacity:0;
         }
         ${props => 100 - (1000 * 100 / (4000 * props.countBrandings))}% {
           z-index: 0;
           opacity:0;
         }
         100% {
           z-index: 20;
           opacity:1;
         }
     }
     ${BrandImageContainer} {
      opacity: 0;
      animation-name: brandFadeInOut;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: ${props => props.countBrandings * 4}s;
    }

    ${BrandImageContainer}:first-child {
      animation-delay: 0s;
    }

    ${BrandImageContainer}:nth-child(2) {
      animation-delay: 4s;
    }

    ${BrandImageContainer}:nth-child(3) {
      animation-delay: 8s;
    }

    ${BrandImageContainer}:nth-child(4) {
      animation-delay: 12s;
    }

    ${BrandImageContainer}:nth-child(5) {
      animation-delay: 16s;
    }

    ${BrandImageContainer}:nth-child(6) {
      animation-delay: 20s;
    }
  `}
`;

const selectCoBranding = createSelector(
  state => state.partner,
  partner => partner?.brandings
);

const Branding = ({className, alwaysReserve}) => {
  const brandings = useSelector(selectCoBranding);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => setIsVisible(true), []);

  if (!brandings?.length) {
    if (alwaysReserve) {
      return <BrandingContainer className={className} countBrandings={brandings.length} />;
    }
    return null;
  }
  const partners = brandings.map(brand => {
    const image = <Image height={20} width={160} eager entity='branding' src={brand.image} fit />;

    const brandImage = brand.url ? (
      <a data-google-interstitial='false' target='_blank' href={brand.url}>
        {image}
      </a>
    ) : (
      image
    );

    return <BrandImageContainer key={brand.url}>{brandImage}</BrandImageContainer>;
  });

  return (
    <BrandingContainer isVisible={isVisible} className={className} countBrandings={brandings.length}>
      {partners}
    </BrandingContainer>
  );
};

export {Branding};
