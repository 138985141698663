import React, {useEffect, useState} from 'react';
import {AdSlot} from 'app/components/ads/AdSlot';
import styled from 'styled-components';
import {adMaxSkyscraperWidth, defaultSpace, desktopContentWidth} from 'app/styles/media';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

const maxHeightSuperbannerWithMargin = 250 + 32;
const defaultAppBarHeight = 72;
const tabBarHeight = 42;

const SCAdSlot = styled(AdSlot)`
  &&& {
    display: none;
    ${props =>
      props.theme.desktopLayoutWithHalfSky`
        display: ${props => (props.left ? 'flex' : 'block')};
        ${props => (props.left ? 'justify-content: flex-end;' : '')};
        background-color: transparent;
        min-width: ${props => (props.left ? '300px' : '150px')};
        min-height: 600px;
        position: ${props => (props.sticky ? 'fixed' : 'absolute')};
        margin-left: ${props => props.marginLeft};
        top: ${props => props.conditionalTopValue}px;
        margin-top: ${props => props.conditionalMarginTop}px;
        align-items: start;
        width: auto;
        height: auto;
        overflow: visible;
    `}
  }
`;

const selectSkipSkyscraper = createSelector(
  state => state.skyscraper,
  (_, skyScraperOnly) => skyScraperOnly,
  (skyscraper, skyScraperOnly) => {
    if (skyScraperOnly) {
      return false;
    } else {
      return skyscraper;
    }
  }
);

const SkyscraperAdSlot = ({...props}) => {
  const appBarHeight = props.initPosition ?? defaultAppBarHeight;
  const skipSky = useSelector(state => selectSkipSkyscraper(state, props.skyscraperOnly));
  const [isSticky, setIsSticky] = useState(props.skyscraperOnly && !props.initPosition);
  const headerHeight = props.noTabs ? appBarHeight : appBarHeight + tabBarHeight;

  const handleScroll = () => {
    if (!props.skyscraperOnly) {
      setIsSticky(window.scrollY >= maxHeightSuperbannerWithMargin);
    } else if (props.stickyThreshold) {
      setIsSticky(window.scrollY >= headerHeight - props.stickyThreshold);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const conditionalTopValueInit = props.stickyThreshold ? props.stickyThreshold : headerHeight;
  const conditionalTopValue = isSticky ? conditionalTopValueInit : headerHeight;
  const conditionalMarginTop = props.stickyThreshold || isSticky ? 0 : maxHeightSuperbannerWithMargin;

  // Place ad only for desktop layout
  // Logic for displaying container inside AdSlot

  const adUnit = props.left ? 'Skyscraper_2' : 'Skyscraper';

  const marginLeft = props.left
    ? `-${adMaxSkyscraperWidth + defaultSpace}px`
    : `${desktopContentWidth + defaultSpace}px`;
  return (
    <SCAdSlot
      {...props}
      marginLeft={marginLeft}
      adLayout='desktop'
      adUnit={adUnit}
      sticky={isSticky}
      skipSky={skipSky}
      conditionalTopValue={conditionalTopValue}
      conditionalMarginTop={conditionalMarginTop}
    />
  );
};

export default SkyscraperAdSlot;
